import React from "react";
import storyStyles from "styles/stories/story.module.scss";

interface DateProps {
	dateTxt: string
	className?: string
}

export const DateItem = ({dateTxt, className}: DateProps) => {
	return (
		<div className={`${storyStyles.date} ${className === undefined ? "" : className}`}>
			<span className="icon-calendar text-white pe-1"/>
			{dateTxt}
		</div>
	)
}