import {Link, useHistory} from "react-router-dom";
import React, {useState} from "react";
import {InputLine} from "../../components/forms/InputLine";
import {useMutation} from "react-query";
import {apiForgotPassword} from "../../adapters/authorization";
import {AxiosError} from "axios";
import {ErrorContactUs} from "./components/ErrorContactUs";
import createSnackbar, {SnackTypes} from "../../components/snackbar/Snackbar";

export const ForgotPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | React.ReactNode>("");
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(false);

  const mutation = useMutation(apiForgotPassword, {
    onSuccess: (response) => {
      createSnackbar(response.data.Message, SnackTypes.success);
      history.push("/login");
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 401) setErrorMessage(error.response.data.Message);
      else                                setErrorMessage(<ErrorContactUs />);
    },
    onSettled: () => {
      setSubmitBtnDisabled(false);
    }
  });

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSubmitBtnDisabled(true);
    mutation.mutate({email: email});
    return;
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
    setErrorMessage("");
  }

  return <>
    <div className="ms-2 mt-2">
      <Link to="/stories" className="wrapper-icon">
        <span className="icon-left_arrow me-2"/>
        <b>Back to Login</b>
      </Link>
    </div>
    <div className="pt-5 pb-5 ps-5 pe-5">
      <header>
        <h1 className="h1 text-black">Forgot Password?</h1>
      </header>
      <div className="mt-3 ps-2 pe-2" style={{backgroundColor: `rgba(255, 255, 255, .7)`}}>
        <p style={{textAlign: "justify"}}>Enter the email address associated with your account and we will send you a link to reset your password.</p>
      </div>
      <div className="mt-3 storychest-bg-color">
        <span className="text-white">{errorMessage}</span>
      </div>
      <div className="mt-3">
        <form method="post" onSubmit={handleSubmit}>
          <InputLine id="email" type="email" onChange={handleEmailChange} />
          <button type="submit" className="btn btn-danger mt-3" disabled={submitBtnDisabled}>
            Request Password Reset
          </button>
        </form>
      </div>
    </div>
  </>
}