import React, {useRef, useState} from "react";
import defaultCharacterImage from "static/default_character_image.jpg";
import {convertHeicImage} from "helpers/images";
import {getFilePath} from "helpers/files";
import styles from "./profileImage.module.scss";

interface ProfileImageProps {
	setFile?: React.Dispatch<React.SetStateAction<File | undefined>>,
	width?: number,
	height?: number,
	provideUpload?: boolean,
	imageURL?: string
}

export const ProfileImage = (
	{
		setFile,
		width = 130,
		height = 130,
		provideUpload = false,
		imageURL
	}
	: ProfileImageProps
) => {

	const iconRef = useRef<HTMLInputElement>(null);
	const [image, setImage] = useState<string>();

	/** Handle file upload only if `provideUpload` = true. */
	const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		// file is undefined if user close file dialog
		const file: File | undefined = e.target.files?.[0];

		if (file !== undefined) {
			const imagePath: string = getFilePath(file);
			const imageURL: string = URL.createObjectURL(file);

			if (imagePath.endsWith(".heic") || imagePath.endsWith(".heif")) {
				const {conversionSuccess, heicImageURL, heicImageBlob} = await convertHeicImage(imageURL);
				if (conversionSuccess) {
					setImage(heicImageURL!);
					setFile!(new File([heicImageBlob!], heicImageURL!));
				}
			}
			else {
				const reader: FileReader = new FileReader();
				reader.onload = () => {
					setImage(reader.result as string);
					setFile!(file);
				}
				reader.readAsDataURL(file as Blob);
			}
		}
	}

	return (<>
		<div
			className={`bg-image rounded-circle image-border-light pos-relative m-auto`}
			style={{width: `${width}px`, height: `${height}px`, backgroundImage: `url("${image}"), url(${imageURL}), url(${defaultCharacterImage})`}}>
			{ provideUpload &&
		      <button
		        type="button"
		        className={`btn p-0 ${styles.openFileDialogBtn}`}
		        onClick={() => iconRef.current?.click()}
		      >
		        <span className={`icon-plus`}/>
		      </button>
			}
		</div>
		<input type="file" ref={iconRef} onChange={handleFileUpload} hidden />
	</>)
}