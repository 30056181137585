import React, {useEffect, useReducer, useRef, useState} from "react";
import "styles/file_dropzone.scss";
import {Dropzone, DropzoneFileProp} from "./components/Dropzone";
import {DragAndDrop} from "./components/DragAndDrop";
import {useAppSelector, usePickDate, usePickGroups} from "app/hooks";
import {RootState} from "app/store";
import Loader from "react-loader-spinner";
import colorsStyle from "styles/modules/colors.module.scss";
import {getMonthName, getTimestampInMilliSeconds} from "helpers/global";
import autosize from 'autosize';
import {
	AddEditStoryParamsType,
	ApiLabelsResponseContentType,
	ApiStoryDetailsContentType,
	CharacterTreeType, MediaFile
} from "../../adapters/types";
import {LabelsModal} from "./components/LabelsModal";
import bgImage from "static/smudges2.jpg";
import createStoryStyles from "styles/stories/create_story.module.scss";
import labelsStyles from "styles/stories/labels.module.scss";
import {CharactersModal} from "../../components/CharactersModal";
import defaultCharacterImage from "../../static/default_character_image.jpg";
import {apiAddStory, apiEditStory} from "../../adapters/stories";
import {Link, useHistory} from "react-router-dom";
import {HeartsLoader} from "../../components/HeartsLoader";
import {Overlay} from "../../components/Overlay";
import {PickDate} from "../../components/PickDate";
import {PickGroups} from "../../components/PickGroups";
import {Accordion, Alert, OverlayTrigger, Tooltip} from "react-bootstrap";
import {InputLine} from "../../components/forms/InputLine";
import {useMutation} from "react-query";
import createSnackbar, {SnackTypes} from "../../components/snackbar/Snackbar";
import {isVideoType} from "../../helpers/validation";
import {AddButton} from "../../components/AddButton";
import {YesNoModal} from "../../components/modals/YesNoModal";
import {AxiosError} from "axios";

export interface FilesState {
	files: DropzoneFileProp[]
}

export enum FilesActionKind {
	SET_FILES,
	UPDATE_FILES
}

export interface FilesAction {
	type: FilesActionKind,
	payload: DropzoneFileProp[]
}

interface CreateEditStoryFormProps {
	storyDetails?: ApiStoryDetailsContentType,
	editingStory: boolean
}

/**
 * @param storyDetails Not undefined when story already exist (saved on the back-end or in the local storage) and user is editing it.
 * @param editingStory
 */
export const CreateEditStoryForm = ({storyDetails, editingStory}: CreateEditStoryFormProps) => {

	const userData = useAppSelector((state: RootState) => state.auth.userData);
	const history = useHistory();

	const [reRender, setReRender] = useState(false);
	const forceUpdate = () => setReRender(!reRender);

	const [convertingLoading, setConvertingLoading] = useState<boolean>(false);
	const [savingLoading, setSavingLoading] = useState(false);

	const acceptedFileExtensions = "image/*, video/*, .heic, .heif, .mkv";

	const [filesState, dispatchFiles] = useReducer((state: FilesState, action: FilesAction) => {
		switch (action.type) {
			case FilesActionKind.SET_FILES:
				return {files: action.payload};
			case FilesActionKind.UPDATE_FILES:
				const _files: DropzoneFileProp[] = [];
				for (const file of action.payload) {
					if (state.files.find(f => f.id === file.id) === undefined)
						_files.push(file);
				}
				return {files: [...state.files, ..._files]};
			default:
				throw new Error();
		}
	},
	{
		files: storyDetails?.MediaFiles.map((file, i) => {
			return ({
				id: i.toString(),
				file: file.Url,
				type: file.Type === 1 ? "video/mp4" : "image/jpeg",
				htmlFile: new File([file.Url], `file${i}.jpg`, {type: file.Type === 1 ? "video/mp4" : "image/jpeg"}),
				conversionBlob: new Blob(),
				mediaId: file.Id
			})}) || []
	});

	/** Media ids. */
	const [filesToDelete, setFilesToDelete] = useState<number[]>([]);

	// ----- states for form input fields

	const [heading, setHeading] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const [labels, setLabels] = useState<ApiLabelsResponseContentType[]>([]);
	const [checkedLabels, setCheckedLabels] = useState<ApiLabelsResponseContentType[]>([]);
	const [storyLocation, setLocation] = useState<string>("");
	// when did this happen
	const dateState = usePickDate(
		storyDetails?.CustomDate.DateYearPart || undefined,
		storyDetails?.CustomDate?.DateMonthPart || undefined,
		storyDetails?.CustomDate.DateDayPart || undefined
	);
	// who does this feature
	const [checkedCharacters, setCheckedCharacters] = useState<Map<number, CharacterTreeType>>(
		new Map(storyDetails?.CharactersTagged.map(character => [character.Id, character])) || new Map()
	);
	/** Use `userData!.characters` to get all user's characters and `characters` to store and get characters displayed
	 *  in modal based on search.
	 *  All filtering must remain order of characters! */
	const [characters, setCharacters] = useState([...userData!.characters]);
	// share story with...
	const [sharedWithPartner, setSharedWithPartner] = useState<boolean>(false);
	const [sharedWithFeatured, setSharedWithFeatured] = useState<boolean>(false);
	const [sharedWithAllCharacters, setSharedWithAllCharacters] = useState<boolean>(false);
	/** map: groupId -> isChecked */
	const groupsState = usePickGroups(new Map(userData?.groups.map(group => [group.Id, false])));
	// share story with - specific characters
	const [specificCheckedCharacters, setSpecificCheckedCharacters] = useState<Map<number, CharacterTreeType>>(new Map());
	/** Use `userData!.characters` to get all user's characters and `characters` to store and get characters displayed
	 *  in modal based on search.
	 *  All filtering must remain order of characters! */
	const [specificCharacters, setSpecificCharacters] = useState([...userData!.characters]);
	const descriptionTextarea = useRef<HTMLTextAreaElement>(null);

	// END ----- states for form input fields

	useEffect(() => {
		if (descriptionTextarea.current)
			autosize(descriptionTextarea.current);
	}, [descriptionTextarea]);

	const apiCallOnSubmit = (!editingStory && apiAddStory) || apiEditStory;

	/** Binary semaphore to determine whether states for input fields are being mutated. We need this to prevent writing
	 *  to the local storage (i.e. triggering `useEffectTimeout`) when `storyDetails` prop changes.
	 *  Note: Using ref (useRef) won't work because ref isn't batched (rendering isn't affected as with states)
	 *  			(https://dev.to/salehmubashar/useref-or-usestate-which-is-better-258j#:~:text=useState%20returns%202%20properties%20or,to%20refresh%20or%20re%2Drender).
	 * */
	const [updatingBatchedStates, setUpdatingBatchedStates] = useState<boolean>(false);
	const [firstTimeUpdatingStates, setFirstTimeUpdatingStates] = useState<boolean>(true);  // binary semaphore
	const [prevTimestamp, setPrevTimestamp] = useState<number>(getTimestampInMilliSeconds());
	const [savingStoryInProgress, setSavingStoryInProgress] = useState<boolean>(false);  // binary semaphore

	/** Updating states of input fields on storyDetails prop change.
	 *  @version react 17.0.2: Batching works in synchronous useEffect i.e. multiple setState calls will get batched.
	 *  @version react 18: about batching: https://blog.saeloun.com/2021/07/22/react-automatic-batching.html#:~:text=Batching%20is%20a%20React%20feature,done%20for%20the%20event%20handlers
	 *  Notation: useEffectBatchedStatesUpdating
	 * */
	useEffect(() => {
		setUpdatingBatchedStates(true);
		setHeading(storyDetails?.Headline || "");
		setDescription(storyDetails?.Description || "");
		setCheckedLabels(storyDetails?.Labels || []);
		setLocation(storyDetails?.Location || "");
		dateState.setSelectedYear(storyDetails?.CustomDate.DateYearPart || undefined);
		dateState.setSelectedMonth(storyDetails?.CustomDate?.DateMonthPart || undefined);
		dateState.setSelectedDay(storyDetails?.CustomDate.DateDayPart || 0);
		setCheckedCharacters(new Map(storyDetails?.CharactersTagged.map(character => [character.Id, character])) || new Map());
		setSharedWithPartner(storyDetails?.ShareInfo?.OnlyPartner || false);
		setSharedWithFeatured(storyDetails?.ShareInfo?.OnlyFeatured || false);
		setSharedWithAllCharacters(storyDetails?.ShareInfo?.Everyone || false);
		groupsState.setSharedGroups(new Map(userData?.groups.map(group => [group.Id, storyDetails?.ShareInfo?.GroupsShared.some(g => g.Id === group.Id) || false])));
		setSpecificCheckedCharacters(new Map(storyDetails?.ShareInfo?.CharactersShared.map(character => [character.Id, character])) || new Map());
	}, [storyDetails]);

	/** Notation: `useEffectTimeout` */
	useEffect(() => {  // preventing simultaneous writing to local storage on some change
		if (updatingBatchedStates) {
			// since in the `useEffectBatchedStatesUpdating` are states batched, we can be sure that at this stage, all have been updated
			setUpdatingBatchedStates(false);
			return;
		}
		if (savingStoryInProgress || firstTimeUpdatingStates) return;
		setSavingStoryInProgress(true);
		setTimeout(() => {
			setSavingStoryInProgress(false);
			setPrevTimestamp(getTimestampInMilliSeconds());
		}, 1000 - Math.min(1000, getTimestampInMilliSeconds() - prevTimestamp));  // call body after this amount of time
	}, [
		heading, description, dateState.selectedYear, dateState.selectedMonth, dateState.selectedDay, checkedLabels,
		storyLocation, reRender, sharedWithAllCharacters, sharedWithFeatured, sharedWithPartner, groupsState.sharedGroups
	]);

	useEffect(() => {
		if (firstTimeUpdatingStates) {
			setFirstTimeUpdatingStates(false);
			return;
		}
		if (savingStoryInProgress) return;
		localStorage.setItem(editingStory ? storyDetails!.Id.toString() : "-1", JSON.stringify({
			Id: editingStory ? storyDetails!.Id : -1,
			Headline: heading,
			Description: description,
			CustomDate: {
				DateYearPart: dateState.selectedYear,
				DateMonthPart: dateState.selectedMonth,
				DateDayPart: dateState.selectedDay
			},
			MediaFiles: [] as MediaFile[],
			Labels: checkedLabels,
			Location: storyLocation,
			CharactersTagged: [...checkedCharacters.values()],
			ShareInfo: {
				CharactersShared: [...specificCheckedCharacters.values()],
				GroupsShared: [...groupsState.sharedGroups.entries()].filter(value => value[1]).map(value => { return {Id: value[0]}}),
				Everyone: sharedWithAllCharacters,
				OnlyFeatured: sharedWithFeatured,
				OnlyPartner: sharedWithPartner
			}
		} as ApiStoryDetailsContentType));
	}, [savingStoryInProgress]);

	const mutation = useMutation(apiCallOnSubmit, {
		onSuccess: (response) => {
			if (response.status !== 200) {
				createSnackbar(response.data.Message, SnackTypes.error);
				return;
			}
			localStorage.removeItem(editingStory ? storyDetails!.Id.toString() : "-1");
			createSnackbar("Story has been saved!", SnackTypes.success);
			history.push(`/story/${response.data.Content.Id}/details`);
		},
		onError: (r: AxiosError) => {
			const serverMsg: string | undefined = r.response?.data?.Message;
			createSnackbar(<p className="m-0 text-white">We were unable to save your story. {serverMsg ? <><br/>Reason: ({r.response?.status}) {serverMsg}</> : ""}</p>, SnackTypes.error);
		},
		onSettled: () => {
			setSavingLoading(false);
		}
	});

	const handleSaveStorySubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		setSavingLoading(true);
		const form: AddEditStoryParamsType = {
			heading: heading,
			description: description,
			location: storyLocation,
			dateyearpart: dateState.selectedYear.toString(),
			datemonthpart: dateState.selectedMonth === 0 ? "" : dateState.selectedMonth.toString(),
			datedaypart: dateState.selectedDay === 0 ? "" : dateState.selectedDay.toString(),
			labels: checkedLabels.map((label) => label.Id),
			charactersTagged: [...checkedCharacters.keys()],
			sharedWith: {
				onlyPartner: sharedWithPartner,
				onlyFeatured: sharedWithFeatured,
				everyone: sharedWithAllCharacters,
				charactersShared: [...specificCheckedCharacters.keys()],
				groupsShared: [...groupsState.sharedGroups.entries()].filter(value => value[1]).map(value => value[0]),
				onlyMe: !sharedWithPartner && !sharedWithFeatured && !sharedWithAllCharacters &&
							  [...groupsState.sharedGroups.values()].every(value => !value) && specificCheckedCharacters.size === 0
			},
			uploadedFiles: [],
		}

		// (edit story) params
		if (editingStory) {
			form.storyId = storyDetails!.Id;
			form.deletedFiles = filesToDelete;
		}

		// TODO: we assume in this part that file can be image or video only
		const structuredFiles = [];
		for (let i = 0; i < filesState.files.length; i++) {
			const file = filesState.files[i];
			if (file.mediaId !== undefined)  // (edit story) this file is already saved
				continue;
			let filetype, renditions;
			if (isVideoType(file.htmlFile.type)) {
				filetype = "1";
				renditions = [{type: "0", filename: `video${i}.mov`}];
				structuredFiles.push({name: `video${i}.mov`, content: file.htmlFile});
			}
			else {
				filetype = "0";
				renditions = [{type: "0", filename: `photo${i}.jpg`}, {type: "1", filename: `photo${i}.jpg`},
					{type: "2", filename: `photo${i}.jpg`}];

				if (file.type === "heic")
					structuredFiles.push({name: `photo${i}.jpg`, content: new File([file.conversionBlob], `photo${i}.jpg`, {type: "image/jpeg"})});
				else
					structuredFiles.push({name: `photo${i}.jpg`, content: file.htmlFile});
			}
			form.uploadedFiles.push({"no": i.toString(), filetype: filetype, renditions: renditions})
		}
		mutation.mutate({story: form, files: structuredFiles, originalFiles: filesState.files});
	}

	const [showLabelsModal, setShowLabelsModal] = useState<boolean>(false);
	const [showSpecificCharactersModal, setShowSpecificCharactersModal] = useState<boolean>(false);
	const [showYourCharactersModal, setShowYourCharactersModal] = useState<boolean>(false);

	const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

	const cancelStory = (discardChanges: boolean = true) => {
		// remove saved changes from the local storage
		if (discardChanges) {
			if (storyDetails !== undefined)
				localStorage.removeItem(storyDetails.Id.toString());
			else
				localStorage.removeItem("-1");
		}
		// redirect to stories (create story) or to story/storyId (edit story)
		history.push(editingStory ? `/story/${storyDetails!.Id}/details` : "/stories");
	}

	return (<>

		{savingLoading ?
			<Overlay>
				<div className={`text-center`}>
					<HeartsLoader/>
					<b>Saving your story 🥰</b>
				</div>
			</Overlay>
			: <></>
		}
		<CharactersModal show={showSpecificCharactersModal} setShow={setShowSpecificCharactersModal}
										 modalTitle="Your characters"
		                 characters={specificCharacters} setCharacters={setSpecificCharacters}
		                 pickCharacters={true} forceUpdate={forceUpdate} checkedCharacters={specificCheckedCharacters}
		/>
		<CharactersModal show={showYourCharactersModal} setShow={setShowYourCharactersModal}
		                 characters={characters} setCharacters={setCharacters} pickCharacters={true} forceUpdate={forceUpdate}
		                 checkedCharacters={checkedCharacters} modalTitle="Your characters"
		/>
		<LabelsModal show={showLabelsModal} setShow={setShowLabelsModal} labels={labels} setLabels={setLabels}
		             checkedLabels={checkedLabels} setCheckedLabels={setCheckedLabels}
		/>
		<div className={`container-fluid content-wrapper text-center`}
				 style={{
					  backgroundImage: `url(${bgImage})`,
					  filter: savingLoading ? "blur(4px)" : "none"  /* using filter: none instead of fliter: blur(0) due to weird
	                                                           d&d offset bug on scrolling. */
				 }}
		>
			<div className="row mt-2">
				<Link to="/stories" className="wrapper-icon">
					<span className="icon-left_arrow me-2"/>
					<span>Back to Stories</span>
				</Link>
			</div>
			<div className="row mt-4">
				<header className="justify-content-center text-center wrapper-icon content-wrapper-header">
					<span className="icon-book headline-icon"/>
					<h1>{editingStory ? "Edit" : "Create"} Story</h1>
				</header>
			</div>
			<div className="row justify-content-center text-start">
				<div className="col-7">

					<form method="post" encType="multipart/form-data" onSubmit={(e) => e.preventDefault()}>
						<InputLine id="heading" value={heading} label="Add title"
						           onChange={(e) => setHeading((e.target as HTMLInputElement).value)}
						/>
						<InputLine id="description" value={description} label="Tell your story" asTextArea inputRef={descriptionTextarea}
						           onChange={(e) => setDescription((e.target as HTMLTextAreaElement).value)}
						/>

						<div className={`${createStoryStyles.inputsGroup}`}>
							<label className="col-form-label" htmlFor="dropzone-media-files">
								Add media
							</label>
							<Alert variant={"secondary"} style={{padding: ".5rem .75rem .5rem .75rem"}}>
								<div className="wrapper-icon" style={{fontSize: ".9em"}}>
									<span className="icon-information-outline me-2"/>
									Currently, no browser supports HEIC format natively.
									To avoid image conversion, please upload images in JPG, JPEG, or PNG format.
								</div>
							</Alert>
							{convertingLoading && <div className={"d-flex align-items-center"}>
									<span>Converting...</span>
									<Loader type="Hearts" color={colorsStyle.storychestColor} height={75} width={75}/>
								</div>
							}
							<Dropzone accept={acceptedFileExtensions} dispatchFiles={dispatchFiles} setLoading={setConvertingLoading}
							          setLocation={setLocation} setDate={dateState.setSelectedDate}
							/>
							<div>
								<DragAndDrop files={filesState.files} dispatchFiles={dispatchFiles} filesToDelete={filesToDelete} setFilesToDelete={setFilesToDelete}/>
								{/*files.length !== 0 && <p className="text-black">* drag & drop files to change their order.</p> &&
	                <span className="icon-bin cursor-pointer d-flex justify-content-end mt-2"
	                      onClick={(e) => setFiles([])}
	                />
								*/}
							</div>
						</div>

						<div className={`${createStoryStyles.inputsGroup}`}>
							<label htmlFor="labels">Add labels</label>
							<div className="d-flex flex-wrap">
								<AddButton onClick={() => setShowLabelsModal(true)} />
								{checkedLabels.map((label, i) => {
									return (
										<div className={labelsStyles.labelItem} key={i.toString()}><p>{label.Title}</p></div>
									)
								})}
							</div>
						</div>

						<div className={`${createStoryStyles.inputsGroup}`}>
							<label htmlFor="day">When did this happen?</label>
						</div>

						<PickDate {...dateState} />

						<div className="row mt-3 mb-3">
							{dateState.selectedMonth === 0 ? `Sometime in ${dateState.selectedYear}.` :
							 dateState.selectedDay === undefined ? `Sometime in ${getMonthName(dateState.selectedMonth.toString())}, ${dateState.selectedYear}.` : ""}
						</div>

						<div className={`${createStoryStyles.inputsGroup}`}>
							<InputLine id="location" value={storyLocation} label="Where did this happen?"
							           onChange={(e) => setLocation(e.target.value)}
							/>
						</div>

						<div className={`${createStoryStyles.inputsGroup}`}>
							<label htmlFor="characters">Who does this feature?</label>
							<div className="d-flex flex-wrap">
								<AddButton
									onClick={() => setShowYourCharactersModal(true)}
									customIconClassName={`${checkedCharacters.size === 0 ? "icon-plus" : "icon-pen"} cursor-pointer text-white`}
								/>
								{Array.from(checkedCharacters).map(([characterId, character], i) => {
									return (
										<img key={`feature-character-${characterId}`} src={character?.ProfileThumbnail || character.ProfileImage}
										     className={`m-1 ${createStoryStyles.featureCharacterImage}`} alt="character's thumbnail"
										     onError={(e) => (e.target as HTMLImageElement).src = defaultCharacterImage}/>
									);
								})}
							</div>
						</div>

						<div className={`${createStoryStyles.inputsGroup}`}>
							<label htmlFor="who">Share story with...</label>
							<input id="who" className="d-none"/>
						</div>

						<div className={`${createStoryStyles.inputsGroup}`}>
							<button
								className={`${sharedWithPartner ? createStoryStyles.active : ""} ${createStoryStyles.shareStoryWithButton} btn`}
								type="button"
								onClick={(e) => setSharedWithPartner(!sharedWithPartner)}
							>
								Partner
							</button>
						</div>

						<div className={`${createStoryStyles.inputsGroup}`}>
							<button
								className={`${sharedWithFeatured ? createStoryStyles.active : ""} ${createStoryStyles.shareStoryWithButton} btn`}
								type="button"
								onClick={(e) => setSharedWithFeatured(!sharedWithFeatured)}
							>
								All featured characters
							</button>
						</div>

						<Accordion className={`${createStoryStyles.inputsGroup}`}>
							<Accordion.Item eventKey="0">
								<Accordion.Header className="font-lato"><div className="flex-grow-1 text-center">Specific characters</div></Accordion.Header>
								<Accordion.Body className="d-flex flex-column align-items-center">
									<div onClick={() => setShowSpecificCharactersModal(true)}>
										<span className={`${specificCheckedCharacters.size === 0 ? "icon-plus_in_circle" : "icon-pen"} 
										      cursor-pointer storychest-color`}
										/>
									</div>
									<div id="specific_characters_gallery">
										{Array.from(specificCheckedCharacters).map(([characterId, character], i) => {
											return (
												<img key={`specific-character-${characterId}`} src={character?.ProfileThumbnail || character.ProfileImage}
												     className={`m-2 ${createStoryStyles.specificCharacterImage}`} alt="character's thumbnail"
												     onError={(e) => (e.target as HTMLImageElement).src = defaultCharacterImage}/>
											);
										})}
									</div>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>

						<PickGroups {...groupsState} />

						<div className={`${createStoryStyles.inputsGroup}`}>
							<button
								className={`${sharedWithAllCharacters ? createStoryStyles.active : ""} ${createStoryStyles.shareStoryWithButton} btn`}
								type="button"
								onClick={(e) => setSharedWithAllCharacters(!sharedWithAllCharacters)}
							>
								All my characters
							</button>
						</div>

						<YesNoModal show={showCancelModal}
												setShow={setShowCancelModal}
												text={"Do you wish to discard your current changes?"}
												handleYes={cancelStory}
												handleNo={() => cancelStory(false)}
						/>

						<div className="text-center m-5">
							<button type="button"
											className="btn btn-outline-secondary me-4"
											onClick={() => setShowCancelModal(true)}
							>
								Cancel
							</button>
							<OverlayTrigger
								key="saveStoryButtonTooltip"
								placement="top"
								overlay={
									<Tooltip id={`tooltip-saveStoryButtonTooltip`}>
										Saving a story is not possible while converting images.
									</Tooltip>
								}
								show={convertingLoading ? undefined : false}
							>
								<div className={"d-inline-block"}>
									<button type="button"
													className="btn btn-storychest"
													disabled={savingLoading || convertingLoading}
													onClick={handleSaveStorySubmit}
									>
										Save Story
									</button>
								</div>
							</OverlayTrigger>
						</div>
					</form>
				</div>
			</div>
		</div>
	</>);
};