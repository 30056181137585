import heic2any from "heic2any";
import exifr from "exifr";

export const imageExists = (src: string) => {
	return new Promise<boolean>((resolve, reject) => {
		const img = new Image();
		img.onload = () => resolve(true);
		img.onerror = () => reject("Image's source doesn't exist.");
		img.src = src;
	});
}

export interface ConvertHeicImageResult {
	conversionSuccess: boolean,
	heicImageURL?: string,
	heicImageBlob?: Blob
}

/**
 *
 * @param heicImageURL
 * @return: URL and blob of the converted image if `ConvertHeicImageResult.conversionSuccess` = true.
 */
export const convertHeicImage = (heicImageURL: string): Promise<ConvertHeicImageResult> => {
	return fetch(heicImageURL)
		.then((res) => res.blob())
		.then((blob) => heic2any({blob, toType: "image/jpeg"}))
		.then((conversionResult) => {
			return {
				conversionSuccess: true,
				heicImageURL: URL.createObjectURL(conversionResult),
				heicImageBlob: conversionResult as Blob
			};
		})
		.catch((e) => {
			console.log(e);
			return {
				conversionSuccess: false
			};
		});
}

export interface ExifOutput {
	location?: {
		latitude: number,
		longitude: number,
	}
	date?: Date
}

export const getExif = async (image: any): Promise<ExifOutput> => {
	const exif = await exifr.parse(image).catch((e) => {
		console.log(e);
		return {};
	});
	if (exif === undefined)
		return {};
	return {
		location: (exif.hasOwnProperty("latitude") && exif.hasOwnProperty("longitude"))
			? {
				latitude: exif.latitude,
				longitude: exif.longitude
				}
			: undefined,
		date: exif.hasOwnProperty("DateTimeOriginal") ? new Date(exif.DateTimeOriginal) : undefined
	}
}