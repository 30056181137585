import React from "react";

export const AddButton = (
	props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {customIconClassName?: string}
) => {
	return (
		<button type="button"
						className="btn btn-storychest d-flex justify-content-center align-items-center rounded-circle"
						style={{width: "40px", height: "40px"}}
						{...props}
		>
			<span className={props.customIconClassName === undefined ? "icon-plus cursor-pointer text-white" : props.customIconClassName}
						style={{fontSize: "1.1em"}}
			/>
		</button>
	)
}