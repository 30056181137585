import React from "react";
import {CreateEditCharacterForm} from "../characters/components/CreateEditCharacterForm";
import {useCharacterDetails} from "app/hooks";

export const Profile = () => {

	const character = useCharacterDetails();

	return (
		<div className={`container-fluid content-wrapper content-wrapper-top-double-spacing text-center`}>
			<div className="row mb-3">
				<header className="wrapper-icon justify-content-center align-items-baseline">
					<span className="icon-user headline-icon"/>
					<h1>Profile</h1>
				</header>
			</div>
			<div className="row justify-content-center text-center mt-5 mb-5">
				<div className="col-xl-5 col-lg-7 col-md-8">
					{character &&
	          <CreateEditCharacterForm character={character}
	                                   editGroups={false}
	                                   editingProfile={true}
	          />
					}
				</div>
			</div>
		</div>
	)
}