import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CharactersState {
	selectedCharacterId?: number
}

const initialState: CharactersState = {};

export const charactersSlice = createSlice({
	name: "characters",
	initialState,
	reducers: {
		setSelectedCharacterId: (state, action: PayloadAction<number>) => {
			state.selectedCharacterId = action.payload;
		},
		clearCharactersState: (state) => {
			state.selectedCharacterId = undefined;
		}
	}
});

export const {
	setSelectedCharacterId,
	clearCharactersState
} =
	charactersSlice.actions;

export default charactersSlice.reducer;