import React, {useEffect, useState} from "react";
import {CreateEditStoryForm} from "./CreateEditStoryForm";
import {useStoryDetails} from "app/hooks";
import {useHistory, useParams} from "react-router-dom";
import {HeartsLoader} from "components/HeartsLoader";
import bgImage from "static/smudges2.jpg";
import {YesNoModal} from "components/modals/YesNoModal";
import createSnackbar, {SnackTypes} from "../../components/snackbar/Snackbar";

/** Wrapper to <CreateEditStoryForm/> component to edit an existing story.
 *  This component is passing details of an existing story or a story from the local storage to the
 *  `storyDetails` prop in CreateEditStoryForm component, where the story from the local storage has priority.
 */
export const EditStory = () => {
	const {storyId} = useParams<{storyId: string}>();

	const {storyDetails, isFetched, errorMessage} = useStoryDetails(parseInt(storyId));
	const [localStorageStoryDetails, setStoryDetails] = useState<string | null>(null);
	const [show, setShow] = useState<boolean>(localStorage.getItem(storyId) !== null);

	const history = useHistory();

	useEffect(() => {
		if (isFetched && errorMessage.length !== 0) {
			createSnackbar(errorMessage, SnackTypes.error);
			history.push("/404");
		}
	}, [errorMessage, history, isFetched, storyDetails]);

	return <>
		<YesNoModal show={show}
								setShow={setShow}
								text={"Do you wish to load previously unsaved changes to this story?"}
								handleYes={() => setStoryDetails(localStorage.getItem(storyId))}
		/>
		{!isFetched
			?
				<div className={`container-fluid content-wrapper content-wrapper-top-double-spacing text-center`}
						 style={{backgroundImage: `url(${bgImage})`}}
				>
					<HeartsLoader/>
					<span>Loading story...</span>
				</div>
			:
				<CreateEditStoryForm storyDetails={localStorageStoryDetails === null ? storyDetails: JSON.parse(localStorageStoryDetails)}
														 editingStory={true}
				/>
		}
	</>
};