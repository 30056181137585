import {Fullscreen, LeftNav, RightNav} from "../../../components/image_gallery/Controls";
import ImageGallery, {ReactImageGalleryItem} from "react-image-gallery";
import React from "react";

export interface StoryImageGalleryProps {
	images: ReadonlyArray<ReactImageGalleryItem>
}

export const StoryImageGallery = ({images}: StoryImageGalleryProps) => {
	return (
		<ImageGallery items={images}
									showPlayButton={false}
									renderLeftNav={(onClick, disabled) =>
										<LeftNav onClick={onClick} disabled={disabled} />
									}
									renderRightNav={(onClick, disabled) =>
										<RightNav onClick={onClick} disabled={disabled} />
									}
									renderFullscreenButton={(onClick, isFullscreen) =>
										<Fullscreen onClick={onClick} isFullscreen={isFullscreen} />
									}
		/>
	);
}