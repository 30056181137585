import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {ApiStoriesResponseContentType, ApiStoriesYearsMonthsContentType} from "adapters/types";

export interface HeaderState {
  text: string;
  subNavigationText: string;
  subNavigationHref: string;
}

export interface StoriesState {
  stories: ApiStoriesResponseContentType[]
  loadedStories: number
}

export interface StoriesYearsMonthsState {
  storiesYearsMonths: ApiStoriesYearsMonthsContentType
}

export interface StoriesLoadingState {
  loading: boolean
}

const initialState: HeaderState & StoriesState & StoriesYearsMonthsState & StoriesLoadingState = {
  stories: [],
  loadedStories: 0,
  storiesYearsMonths: {Years: []},
  loading: false,
  text: "",
  subNavigationText: "",
  subNavigationHref: ""
};

export const storiesSlice = createSlice({
  name: "stories",
  initialState,
  reducers: {
    setStories: (state, action: PayloadAction<ApiStoriesResponseContentType[]>) => {
      state.stories = action.payload;
      state.loadedStories = 16;
    },
    clearStories: (state) => {
      state.stories = [];
      state.loadedStories = 0;
    },
    setLoadedStories: (state, action: PayloadAction<number>) => {
      state.loadedStories = action.payload;
    },
    setStoriesYearsMonths: (state, action: PayloadAction<ApiStoriesYearsMonthsContentType>) => {
      state.storiesYearsMonths = action.payload;
    },
    clearStoriesYearsMonths: (state) => {
      state.storiesYearsMonths = {Years: []};
    },
    toggleLoading: (state) => {
      state.loading = !state.loading;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    changeText: (state, action: PayloadAction<string>) => {
      state.text = action.payload;
    },
    changeSubNavigationText: (state, action: PayloadAction<string>) => {
      state.subNavigationText = action.payload;
    },
    changeSubNavigationHref: (state, action: PayloadAction<string>) => {
      state.subNavigationHref = action.payload;
    }
  }
});

export const {
  setStories,
  clearStories,
  setLoadedStories,
  setStoriesYearsMonths,
  clearStoriesYearsMonths,
  toggleLoading,
  startLoading,
  stopLoading,
  changeText,
  changeSubNavigationText,
  changeSubNavigationHref
} = storiesSlice.actions;

export default storiesSlice.reducer;
