import styles from "styles/footer.module.scss";

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div id={styles.footerLeft}>
          <p className="mb-1">
            &copy; Storychest Limited
          </p>
        </div>
        <div id={styles.footerRight}>
          <ul className="inline-list">
            <li><a href="https://web.storychest.com/how-it-works/">How It Works</a></li>
            <li><a href="https://web.storychest.com/#testimonials">Testimonials</a></li>
            <li><a href="https://web.storychest.com/message-from-our-founder/">Message from our Founder</a></li>
            <li><a href="https://web.storychest.com/#contact">Contact us</a></li>
            <li><a href="https://web.storychest.com/faqs/">FAQs</a></li>
            <li><a href="https://web.storychest.com/blog/">Blog</a></li>
          </ul>
          <ul className="inline-list">
            <li><a href="https://web.storychest.com/terms-of-service/">Terms of Service</a></li>
            <li><a href="https://web.storychest.com/privacy-policy">Privacy Policy</a></li>
          </ul>
          <ul className="inline-list mt-2">
            <li>
              <a href="https://www.facebook.com/storychest/">
                <span className="icon-facebook text-white"/>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/storychest_">
                <span className="icon-twitter text-white"/>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/storychest_/">
                <span className="icon-instagram text-white"/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}