import {useAppDispatch, useAppSelector, usePickGroups} from "app/hooks";
import {CharacterTreeType} from "adapters/types";
import {PickGroups} from "components/PickGroups";
import React from "react";
import {RootState} from "app/store";
import {apiEditCharacterGroups} from "adapters/characters";
import createSnackbar, {SnackTypes} from "components/snackbar/Snackbar";
import {addOrEditCharacterToUserData} from "pages/authorization/context/authSlice";
import {useMutation} from "react-query";
import {AxiosError} from "axios";

interface CharacterTreeProps {
	character: CharacterTreeType
}

export const EditCharacterGroups = ({character}: CharacterTreeProps) => {

	const dispatch = useAppDispatch();
	const userData = useAppSelector((state: RootState) => state.auth.userData);
	const groupsState = usePickGroups(
		new Map(userData?.groups.map(group => [group.Id, character?.GroupsIds.some(groupId => groupId === group.Id) || false]))
	);

	const editCharacterGroupsMutation = useMutation(apiEditCharacterGroups, {
		onSuccess: (response, variables) => {
			character.GroupsIds = variables.groupIds;
			dispatch(addOrEditCharacterToUserData(character));
			createSnackbar("Groups saved!", SnackTypes.success);
		},
		onError: (error: AxiosError) => {
			createSnackbar(error.response?.data.Message, SnackTypes.error)
		}
	});

	const editCharacterGroups = () => {
		editCharacterGroupsMutation.mutate({
			characterIds: [character.Id],
			groupIds: Array.from(groupsState.sharedGroups.entries())
										 .filter((entry) => entry[1])
										 .map((entry) => entry[0])
		});
	}

	return (<>
		<div className={`mb-4`}>
			<PickGroups {...groupsState} showAccordionBody={true}  />
		</div>
		<button className="btn btn-storychest mt-4" onClick={editCharacterGroups}>
			Save
		</button>
	</>)
}