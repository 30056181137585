import colorsStyle from "styles/modules/colors.module.scss";
import Loader from "react-loader-spinner";
import React from "react";

interface HeartsLoaderProps {
	width?: number
	height?: number
}

export const HeartsLoader = ({width, height}: HeartsLoaderProps) => {
	return (
		<Loader type="Hearts"
		        color={colorsStyle.storychestColor}
		        width={width !== undefined ? width : 125}
		        height={height !== undefined ? height : 125}
		/>
	)
}