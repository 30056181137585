import React, {useState} from "react";
import {CreateEditStoryForm} from "./CreateEditStoryForm";
import {YesNoModal} from "components/modals/YesNoModal";

/** Wrapper to <CreateEditStoryForm/> component to create a new story.
 *  This component is passing undefined or a story from the local storage to the
 *  `storyDetails` prop in CreateEditStoryForm component.
 */
export const CreateStory = () => {

	const [storyDetails, setStoryDetails] = useState<string | null>(null);
	const [show, setShow] = useState<boolean>(localStorage.getItem("-1") !== null);

	return <>
		<YesNoModal show={show}
								setShow={setShow}
								text={"Do you wish to load a previously unsaved story?"}
								handleYes={() => setStoryDetails(localStorage.getItem("-1"))}
		/>
		<CreateEditStoryForm
			storyDetails={storyDetails === null ? undefined : JSON.parse(storyDetails)}
			editingStory={false}
		/>
	</>
};