import "./styles/base.scss";
import "./styles/fonts.scss";
import "./styles/scrollbar.scss";
import {Navbar} from "./components/navbar/Navbar";
import {StoriesLayout} from "./pages/stories/StoriesLayout";
import {Router} from "react-router";
import {Route, Switch, Redirect} from "react-router-dom";
import {NotFound} from "./pages/errors/NotFound";
import {Footer} from "./components/Footer";
import {useAppSelector} from "./app/hooks";
import {RootState} from "./app/store";
import {Logout} from "./pages/authorization/Logout";
import {CreateStory} from "./pages/stories/CreateStory";
import {EditStory} from "./pages/stories/EditStory";
import {StoryDetails} from "./pages/stories/StoryDetails";
import {Profile} from "./pages/profile/Profile";
import {Grapevine} from "./pages/grapevine/Grapevine";
import {Characters} from "./pages/characters/Characters";
import {ToastContainer} from "react-toastify";
import {createBrowserHistory} from 'history';
import {setupInterceptors} from "./app/axiosProvider";
import {LoginLayout} from "./pages/authorization/LoginLayout";
import {useQuery} from "react-query";
import {apiGetUserDetails} from "./adapters/characters";
import {setUserData, getAccessToken} from "./app/localStorage";

const history = createBrowserHistory();
setupInterceptors(history);

const App = () => {

  // On the first app load fetch user details (the same data that we get after successful login in response)
  useQuery("getUserDetails", apiGetUserDetails, {
    onSuccess: (response) => {
      // This is the only case where we are using setTokens = false. The reason is the following edge case:
      // 401 on this request -> refresh token -> set new access & refresh tokens -> repeating this request -> received
      // user details with cached (old) tokens -> user would be logged out on the next request due to invalid access token.
      setUserData(response.data.Content, false);
    },
    enabled: getAccessToken() !== undefined
  });

  return (
    <Router history={history}>
      <Navbar/>
      <Switch>
        <Route exact path={["/login", "/forgot-password"]} component={LoginLayout}/>
        <Route exact path="/logout" component={Logout}/>
        <Redirect exact from="/" to="/stories"/>
        <AuthRoute exact path={["/stories", "/stories/list-by-years", "/stories/:year/months", "/stories/:year/:month"]}
                   component={StoriesLayout}>
        </AuthRoute>
        <AuthRoute exact path="/story/create" component={CreateStory}/>
        <AuthRoute exact path="/story/:storyId/edit" component={EditStory}/>
        <AuthRoute exact path="/story/:storyId/details" component={StoryDetails}/>
        <AuthRoute exact path="/profile" component={Profile}/>
        <AuthRoute exact path="/grapevine" component={Grapevine}/>
        <AuthRoute exact path="/character/:characterId/stories" component={Grapevine}/>
        <AuthRoute exact path="/characters" component={Characters}/>
        <Route exact path="*" component={NotFound} />
      </Switch>
      <Footer/>
      <ToastContainer limit={5} />
    </Router>
  );
};

export const AuthRoute = ({component: Component, ...routeProps}: any) => {
  const isUserLoggedIn = useAppSelector((state: RootState) => state.auth.isUserLoggedIn);
  return (
    <Route
      {...routeProps}
      render={(props) => {
        if (typeof routeProps.path !== "object" && routeProps.path === "/login")
          return !isUserLoggedIn ? <Component {...props}/> : <Redirect to="/stories"/>;
        return isUserLoggedIn ? <Component {...props}/> : <Redirect to="/login"/>;
      }}
    />
  );
}

export default App;