import {CharacterTreeType} from "adapters/types";
import {CharactersModalBody} from "components/CharactersModal";
import {useEffect, useState} from "react";
import {apiAddCharactersToTree} from "adapters/characters";
import createSnackbar, {SnackTypes} from "components/snackbar/Snackbar";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {RootState} from "app/store";
import {addCharactersToUserData} from "pages/authorization/context/authSlice";
import {useMutation} from "react-query";
import {AxiosError} from "axios";

interface CharacterTreeProps {
	character: CharacterTreeType
}

export const CharacterTree = ({character}: CharacterTreeProps) => {

	const dispatch = useAppDispatch();
	const userData = useAppSelector((state: RootState) => state.auth.userData);
	const [reRender, setReRender] = useState(false);
	const forceUpdate = () => setReRender(!reRender);
	const [checkedCharacters, setCheckedCharacters] = useState<Map<number, CharacterTreeType>>(new Map());
	const [characters, setCharacters] = useState<CharacterTreeType[]>([]);

	useEffect(() => {
		if (character.CharacterTree !== undefined)
			setCharacters([...character.CharacterTree!]);
	}, [character.CharacterTree]);

	const addCharacterToTreeMutation = useMutation(apiAddCharactersToTree, {
		onSuccess: () => {
			dispatch(addCharactersToUserData(Array.from(checkedCharacters.values())));
			setCheckedCharacters(new Map());
			createSnackbar("Characters saved!", SnackTypes.success);
		},
		onError: (error: AxiosError) => {
			createSnackbar(error.response?.data.Message, SnackTypes.error);
		}
	});

	const addCharactersToTree = () =>
		addCharacterToTreeMutation.mutate({characterIds: Array.from(checkedCharacters.keys())});

	const isInUserTree = (characterId: number): boolean => {
		const ch: CharacterTreeType | undefined = userData?.characters.find(ch => ch.Id === characterId);
		return ch !== undefined;
	}

	return (<>
		<CharactersModalBody characters={characters} setCharacters={setCharacters}
		                     pickCharacters={true} forceUpdate={forceUpdate} checkedCharacters={checkedCharacters}
		                     showAdditionalInfo={(character: CharacterTreeType) =>
			                     isInUserTree(character.Id) ? "already in your tree" : ""}
		/>
		<button className="btn btn-storychest mt-4" onClick={addCharactersToTree}>
			Add Characters
		</button>
	</>)
}