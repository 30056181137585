import {CharacterTreeType, CharacterType} from "adapters/types";
import defaultCharacterImage from "static/default_character_image.jpg";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import styles from "styles/stories/characters_modal.module.scss"
import {ModalShowProps} from "app/types";
import {Modal} from "react-bootstrap";
import {useAppSelector} from "../app/hooks";
import {RootState} from "../app/store";

interface CharacterItemBase {
	forceUpdate: () => void,
	leftRightCharactersSwitching?: boolean,
	checkedCharacters?: Map<number, CharacterType>,
	showAdditionalInfo?: (character: CharacterTreeType) => string
}

type CharacterItemProps = CharacterItemBase & {
	character: CharacterTreeType,
	itemIndex: number
}

type CharactersModalBodyProps = CharacterItemBase & {
	characters: CharacterTreeType[],
	setCharacters?: Dispatch<SetStateAction<CharacterTreeType[]>>,
	pickCharacters: boolean,
	allowSearch?: boolean
}

type CharactersModal = ModalShowProps & {
	modalTitle: string
}

type CharactersModalProps = CharactersModalBodyProps & CharactersModal

/**
 * @param characters
 * @param setCharacters
 * @param checkedCharacters Map from character's id to character's tree.
 * @param setCheckedCharacters
 * @param pickCharacters
 * @param allowSearch If true, `setCharacters` must be set.
 * @param forceUpdate Needed for re-render after `checkedCharacters` change.
 * @param leftRightCharactersSwitching
 * @param modalTitle
 * @constructor
 */
export const CharactersModal = ({
          show,
          setShow,
          characters,
					setCharacters,
					checkedCharacters,
					pickCharacters,
					allowSearch = true,
					forceUpdate,
					leftRightCharactersSwitching = false,
					showAdditionalInfo,
					modalTitle
					}: CharactersModalProps
) => {

	const handleClose = () => setShow(false);

	return (
		<Modal
			show={show}
			onHide={handleClose}
		>
			<Modal.Header closeButton>
				<div className="d-flex flex-column">
					<div className="wrapper-icon">
						<h5>{modalTitle}</h5>
						<span className="icon-users storychest-color ms-1"/> <br/>
					</div>
					<div>{pickCharacters && <small className="text-dark">*add by clicking on the character</small>}</div>
				</div>
			</Modal.Header>
			<CharactersModalBody characters={characters} setCharacters={setCharacters} checkedCharacters={checkedCharacters}
			                     pickCharacters={pickCharacters} allowSearch={allowSearch} forceUpdate={forceUpdate}
			                     leftRightCharactersSwitching={leftRightCharactersSwitching} showAdditionalInfo={showAdditionalInfo}
			/>
			<Modal.Footer>
				<div>
					<button type="button" className="btn btn-outline-secondary" onClick={handleClose}>Done</button>
				</div>
			</Modal.Footer>
		</Modal>
	)
}

// TODO (BUG): Once originalCharacters were set from (not empty) `characters`, change in `characters` would not affect
// TODO: this component without re-mounting.
export const CharactersModalBody = ({
    characters,
    setCharacters,
    checkedCharacters,
    pickCharacters,
    allowSearch = true,
    forceUpdate,
    leftRightCharactersSwitching = false,
		showAdditionalInfo
  }: CharactersModalBodyProps
) => {

	const [originalCharacters, setOriginalCharacters] = useState<CharacterTreeType[]>([]);

	// create copy of the `characters` once they're not empty
	useEffect(() => {
		if (characters.length !== 0 && originalCharacters.length === 0)
			setOriginalCharacters([...characters]);
	}, [characters]);

	return (
		<Modal.Body className={`${styles.modalBody}`}>
			{allowSearch &&
      <input type="search" id="search-characters" className={`form-control ${styles.modalInputBottomLine}`}
             placeholder="Search"
             onChange={(e) => {
				       let searchValue = e.target.value.toLowerCase().trim().split(/(\s+)/)
					       .filter((s) => s.trim().length > 0).join(' ');
				       if (searchValue === "") {
					       setCharacters!([...originalCharacters]);
					       return;
				       }
				       const _characters: CharacterTreeType[] = [];
				       let regex = new RegExp(`.*${searchValue}.*`);
				       originalCharacters.forEach((character) => {
					       const characterName = `${character.FirstName.toLowerCase()} ${character.LastName.toLowerCase()}`;
					       if (regex.test(characterName))
						       _characters.push(character);
				       });
				       setCharacters!(_characters);
			       }}
      />
			}
			<div className={`${styles.charactersContainer} container-fluid`}>
				{characters.map((character, i) =>
					<CharacterItem key={`character-${character.Id}`} showAdditionalInfo={showAdditionalInfo}
												 character={character} itemIndex={i} checkedCharacters={checkedCharacters}
					               forceUpdate={forceUpdate} leftRightCharactersSwitching={leftRightCharactersSwitching}
					/>
				)}
			</div>
		</Modal.Body>
	)
}

const CharacterItem = ({
	 character,
	 itemIndex,
   checkedCharacters,
   forceUpdate,
   leftRightCharactersSwitching,
	 showAdditionalInfo
 }: CharacterItemProps
) => {

	const userData = useAppSelector((state: RootState) => state.auth.userData);

	return (
		<div className={
						`row ${styles.characterItemContainer} 
	          ${leftRightCharactersSwitching && itemIndex % 2 !== 0 ? "ml-auto" : "mr-auto"} 
	          ${checkedCharacters !== undefined && checkedCharacters.has(character.Id) ? styles.active : ""}`
		     }
		     onClick={() => {
			     if (checkedCharacters === undefined) return;
			     if (checkedCharacters.has(character.Id))
				     checkedCharacters.delete(character.Id);
			     else
				     checkedCharacters.set(character.Id, character);
			     forceUpdate();
		     }}>
			<div className={`col-12 ${styles.characterItem} position-relative`}>
				<img src={character.hasOwnProperty("ProfileThumbnail") ? character.ProfileThumbnail : character.ProfileImage}
				     alt="character's thumbnail"
				     onError={(e) => (e.target as HTMLImageElement).src = defaultCharacterImage}/>
				<span className={styles.characterName}>
					{character.FirstName} {character.LastName} {character.Id === userData!.userId ? " (me)" : ""}
				</span>
				<small className="pos-absolute bottom-0 right-0">{
					showAdditionalInfo === undefined ? "" : showAdditionalInfo(character)
				}</small>
			</div>
		</div>
	)
}