import { ReactNode } from "react";

interface InputButtonGroupProps {
	className?: string,
	inputClassName?: string,
	inputStyle?: React.CSSProperties,
	placeholder: string,
	value?: string,
	handleChange: (value: string) => any
	handleClick: () => any,
	handleEnterPress?: () => any,
	customIcon?: ReactNode
}

export const InputButtonGroup = ({
	 className, inputClassName = "", placeholder, value = "", handleChange, handleClick, handleEnterPress, inputStyle, customIcon
}: InputButtonGroupProps) => {
  return (
	  <div className={`input-group ${className}`}>
		  <input type="text"
		         value={value}
		         className={inputClassName}
		         placeholder={placeholder}
		         onChange={(e) => handleChange(e.target.value)}
		         onKeyPress={(e) => handleEnterPress ? e.key === "Enter" && handleEnterPress() : () => {}}
		         style={inputStyle}
		  />
		  <button className="btn btn-outline-secondary" onClick={handleClick}>
			  <div className="d-flex align-items-center">
				  {customIcon !== undefined ? customIcon : <span className="icon-plus_in_circle" />}
			  </div>
		  </button>
	  </div>
  )
}