import React, {useEffect} from "react";
import {Redirect, useLocation} from "react-router-dom";
import {logoutUser} from "./context/authSlice";
import {useAppDispatch} from "../../app/hooks";

export const Logout = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  return (
    <Redirect to={{
      pathname: "/login",
      state: location.state === undefined ? {} : location.state
    }}/>
  );
};