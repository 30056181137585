import React, {ChangeEventHandler} from "react";
import {capitalize} from "helpers/global";

export interface InputLineProps {
	id: string,
	type?: string,
	value?: string
	onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
	required?: boolean,
	placeholder?: string,
	label?: string,
	asTextArea?: boolean,
	inputRef?: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>
}

export const InputLine = (_props: InputLineProps) => {
	const props = {required: true, asTextArea: false, inputRef: null, ..._props};
	// TODO: possible optimization
	const inputProps = {
		id: props.id,
		name: props.id,
		placeholder: props.placeholder ? props.placeholder : capitalize(props.id),
		className: "input-line form-control",
		value: props.value,
		onChange: props.onChange,
		required: props.required
	}

	return (
	  <div className="mb-3 pos-relative">
		  {props.label && <label htmlFor={props.id}>{props.label}</label>}
		  {props.asTextArea
			  ? <textarea {...inputProps}
			              ref={props.inputRef as React.MutableRefObject<HTMLTextAreaElement>}
										style={{boxShadow: "none"}}
			    />
		    : <input {...inputProps}
		             type={props.type ? props.type : "text"}
		             ref={props.inputRef as React.MutableRefObject<HTMLInputElement>}
			    />
		  }
		  <span className="focus-border" />
	  </div>
  )
}