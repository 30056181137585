import {DropdownScrollMenu} from "./DropdownScrollMenu";
import {getMonthName, map, range} from "../helpers/global";
import {DayPicker} from "react-day-picker";
import React, {useEffect, useState} from "react";
import {UsePickDateReturnType} from "../app/hooks";
import {Dropdown} from "react-bootstrap";
import 'react-day-picker/dist/style.css';
import styles from "styles/day_picker.module.scss";

export const PickDate = (dateState: UsePickDateReturnType) => {

	const [dateYearMonth, setDateYearMonth] = useState<Date>();
	const [date, setDate] = useState<Date>();

	useEffect(() => {
		setDateYearMonth(new Date(dateState.selectedYear, dateState.selectedMonth - 1));
		setDate(new Date(dateState.selectedYear, dateState.selectedMonth - 1, dateState.selectedDay));
	}, [dateState]);

  return <>
	  <div className="row gx-2">
			<div className="col-auto">
			  <DropdownScrollMenu menuId="year" selectedValue={dateState.selectedYear} setSelectedValue={dateState.setSelectedYear}
														className="text-center"
			                      dropdownMenuTitle="Years" dropdownMenuData={() => range(new Date().getFullYear(), 1799, -1)}/>
		  </div>
		  <div className="col-auto">
			  <DropdownScrollMenu menuId="month"
			                      selectedValue={dateState.selectedMonth === 0 ? "Pick month" : getMonthName(dateState.selectedMonth.toString())}
			                      setSelectedValue={dateState.setSelectedMonth}
														className="text-center"
			                      dropdownMenuTitle="Months">
				  {map(range(0, 13), (month: number) => {
					  return (
							<Dropdown.Item as="div" key={`select-month-${month}`} className="dropdown-item cursor-pointer"
														 onClick={_ => dateState.setSelectedMonth(month)}
							>
								{getMonthName(month)}
							</Dropdown.Item>
					  )
				  })}
			  </DropdownScrollMenu>
		  </div>
		  <div className="col-auto">
			  { dateState.selectedMonth !== 0 && (
				  <DropdownScrollMenu menuId="day" selectedValue={dateState.selectedDay === 0 ? "Pick day" : dateState.selectedDay}
				                      setSelectedValue={dateState.setSelectedDay} css={{width: "305px", height: "305px"}}>
					  <DayPicker
							mode="single"
						  month={dateYearMonth}
						  fromMonth={dateYearMonth}
						  toMonth={dateYearMonth}
						  selected={date}
						  onDayClick={(date, {selected}) => dateState.setSelectedDay(selected ? 0 : date.getDate())}
							modifiersClassNames={{
								selected: styles.selectedDay
							}}
					  />
				  </DropdownScrollMenu>
			  )}
		  </div>
	  </div>
  </>
}