import {Dispatch, SetStateAction, useCallback} from "react";
import {useDropzone} from 'react-dropzone';
import {convertHeicImage, getExif} from "helpers/images";
import {getFilePath} from "helpers/files";
import axios from "axios";
import {isVideoType} from "../../../helpers/validation";
import createSnackbar, {SnackTypes} from "../../../components/snackbar/Snackbar";
import {FilesAction, FilesActionKind} from "../CreateEditStoryForm";

/** Warning: DragAndDrop.tsx will be using this type.
 *  @mediaId Number, if file is already uploaded and saved (editing story).
 * */
export interface DropzoneFileProp {
	id: string,
	file: string,
	type: string,
	htmlFile: File,
	conversionBlob: Blob,
	mediaId?: number
}

export interface DropzoneProps {
	accept: string,
	dispatchFiles: Dispatch<FilesAction>,
	setLoading: Dispatch<SetStateAction<boolean>>,
	setLocation: Dispatch<SetStateAction<string>>,
	setDate: Function
}

/**
 * @param accept What files to accept.
 * @param files State from parent's component.
 * @param dispatchFiles Update state in parent's component.
 * @constructor
 */
export const Dropzone = ({accept, dispatchFiles, setLoading, setLocation, setDate}: DropzoneProps) => {
	
	const onDrop = useCallback(async (acceptedFiles: any) => {
		let droppedFiles = [];
		for (const file of acceptedFiles) {
			const filePath = getFilePath(file);
			let fileURL = URL.createObjectURL(file);

			let newFile: DropzoneFileProp = {id: "", file: "", htmlFile: new File([], ""), conversionBlob: new Blob(), type: ""};

			if (!isVideoType(file.type)) {
				const exif = await getExif(fileURL);
				if (exif.location) {
					const {data} = await axios.get(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${exif.location.latitude}&longitude=${exif.location.longitude}&localityLanguage=en`)
					const locationParts = [data.locality, data.city, data.principalSubdivision, data.countryName, data.continent];
					setLocation(locationParts.filter(s => s).join(", "));
				}
				if (exif.date)
					setDate(exif.date);
			}

			const lowerCaseFilePath = filePath.toLocaleLowerCase();

			if (lowerCaseFilePath.endsWith(".heic") || lowerCaseFilePath.endsWith(".heif")) {
				setLoading(true);
				const {conversionSuccess, heicImageURL, heicImageBlob} = await convertHeicImage(fileURL);
				if (!conversionSuccess) {
					createSnackbar(`Unable to convert image ${file.name}. Please, try to upload it in JPEG format.`, SnackTypes.error);
					continue;
				}
				fileURL = heicImageURL!;
				newFile.conversionBlob = heicImageBlob!;
				newFile.type = "heic";
			}
			newFile.id = filePath;
			newFile.file = fileURL;
			newFile.type = newFile.type === "" ? file.type : newFile.type;
			newFile.htmlFile = file;
			droppedFiles.push(newFile);
		}
		setLoading(false);
		dispatchFiles({type: FilesActionKind.UPDATE_FILES, payload: droppedFiles});
	}, [dispatchFiles, setDate, setLoading, setLocation]);

	const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept})

	return (
		<div {...getRootProps({className: 'dropzone'})}>
			<input {...getInputProps()} id="dropzone-media-files" />
			<p className="wrapper-icon">
				Drag & drop your files here, or click to select files
				<span className="icon-plus_in_circle ps-1 storychest-color"/>
			</p>
		</div>
	);
}