import {
	AddCharacterParamsType,
	ApiCharacterDetailsResponse,
	ApiCharactersResponse,
	ApiGroupResponseType, ApiLoginResponse,
	ApiResponse, FormFile
} from "./types";
import {AxiosResponse} from "axios";
import {axiosProvider as axios} from "app/axiosProvider";
import {createFormData} from "../helpers/api";

export interface ApiCharacterProps {
	character: AddCharacterParamsType,
	files: FormFile[]
}

export const apiGetUserDetails = (): Promise<AxiosResponse<ApiLoginResponse>> =>
	axios.post(`/user/details`);

const apiCharacter = (props: ApiCharacterProps, url: string): Promise<AxiosResponse<ApiCharacterDetailsResponse>> => {
	const formData = createFormData(props.character, props.files);
	return axios.post(url, formData, {
		headers: {"Content-Type": "multipart/form-data"}
	});
}

export const apiAddCharacter = (props: ApiCharacterProps): Promise<AxiosResponse<ApiCharacterDetailsResponse>> =>
	apiCharacter(props, "/characters/add");

export const apiEditCharacter = (props: ApiCharacterProps): Promise<AxiosResponse<ApiCharacterDetailsResponse>> =>
	apiCharacter(props, "/character/edit");

export const apiEditUser = (props: ApiCharacterProps): Promise<AxiosResponse<ApiCharacterDetailsResponse>> =>
	apiCharacter(props, "/user/edit");

export const apiDeleteCharacter = (props: {characterId: number}): Promise<AxiosResponse<ApiResponse>> =>
	axios.post(`/character/${props.characterId}/delete`);

export const apiDeleteStorychestUserCharacter = (props: {characterId: number}): Promise<AxiosResponse<ApiResponse>> =>
	axios.post(`/character/${props.characterId}/remove-from-tree`);

export const apiGetCharacterDetails = (props: {characterId: number}): Promise<AxiosResponse<ApiCharacterDetailsResponse>> =>
	axios.post(`/character/${props.characterId}/details`);

export const apiGetCharactersByEmail = (props: {email: string}): Promise<AxiosResponse<ApiCharacterDetailsResponse>> =>
	axios.post("/characters/findbyemail", {email: props.email});

export const apiCharactersBrowse = (): Promise<AxiosResponse<ApiCharactersResponse>> =>
	axios.post("/characters/browse");

export const apiAddCharactersToTree = (props: {characterIds: number[]}): Promise<AxiosResponse<ApiResponse>> =>
	axios.post("/characters/add-to-tree", {characterIds: props.characterIds});

export const apiAddNewGroup = (props: {newGroupTitle: string}): Promise<AxiosResponse<ApiGroupResponseType>> =>
	axios.post("/characters/add-group", {groupTitle: props.newGroupTitle});

export const apiEditCharacterGroups = (props: {characterIds: number[], groupIds: number[]}): Promise<AxiosResponse<ApiResponse>> =>
	axios.post("/characters/edit-groups", {characterIds: props.characterIds, groupIds: props.groupIds});