import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {apiDeleteStory} from "../../adapters/stories";
import "react-image-gallery/styles/css/image-gallery.css";
import debounce from "lodash.debounce";
import {setLoadedStories, setStories} from "./context/storiesSlice";
import {PreviewStoryModal} from "./components/PreviewStoryModal";
import {YesNoModal} from "../../components/modals/YesNoModal";
import createSnackbar, {SnackTypes} from "../../components/snackbar/Snackbar";
import {StoryItem} from "./components/StoryItem";
import {useMutation} from "react-query";

interface StoriesListProps {
  haveAtLeastOneStory?: boolean
}

export const StoriesList = ({haveAtLeastOneStory = false}: StoriesListProps) => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state: RootState) => state.stories.loading);
  const stories = useAppSelector((state: RootState) => state.stories.stories);
  const numberOfLoadedStories = useAppSelector((state: RootState) => state.stories.loadedStories);

  const storiesPerPage = 16;

  const [selectedStoryId, setSelectedStoryId] = useState(-1);
  const [toDeleteStoryId, setToDeleteStoryId] = useState(-1);

  /** Infinity scrolling. */
  window.onscroll = debounce(() => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 300) {
      if (numberOfLoadedStories <= stories.length)
        dispatch(setLoadedStories(numberOfLoadedStories + storiesPerPage));
    }
  }, 100);

  const [showStoryPreviewModal, setShowStoryPreviewModal] = useState<boolean>(false);
  const [showYesNoModal, setShowYesNoModal] = useState<boolean>(false);
  const [yesNoModalText, setYesNoModalText] = useState("Are you sure you want to delete story ?");

  const mutation = useMutation(apiDeleteStory, {
    onSuccess: () => {
      dispatch(setStories(stories.filter((story) => story.Id !== toDeleteStoryId)));
      createSnackbar("Story has been deleted.", SnackTypes.success);
    }
  });

  const deleteStoryOnYes = () => mutation.mutate({storyId: toDeleteStoryId});

  useEffect(() => () => {  // clean up (closing modals)
    setShowStoryPreviewModal(false);
    setShowYesNoModal(false);
  }, []);

  return <>
    <PreviewStoryModal show={showStoryPreviewModal} setShow={setShowStoryPreviewModal} storyId={selectedStoryId}/>
    <YesNoModal show={showYesNoModal} setShow={setShowYesNoModal} text={yesNoModalText} handleYes={deleteStoryOnYes}/>

    {(stories.length === 0 && !loading && !haveAtLeastOneStory)
      ? <b>No stories found.</b>
      :
        stories.slice(0, numberOfLoadedStories).map((story) => { return (
          <StoryItem key={`storyPreview${story.Id}`}
                     story={story}
                     setShowStoryPreviewModal={setShowStoryPreviewModal}
                     setSelectedStoryId={setSelectedStoryId}
                     setShowYesNoModal={setShowYesNoModal}
                     setYesNoModalText={setYesNoModalText}
                     setToDeleteStoryId={setToDeleteStoryId}
          />
    )})}
  </>
}