import {ApiGrapevineResponseType, ApiResponse} from "./types";
import {axiosProvider as axios} from "app/axiosProvider";
import {AxiosResponse} from "axios";

export const apiGetGrapevine = (): Promise<AxiosResponse<ApiGrapevineResponseType>> => {
	return axios.post("/get-grapevine");
};

export const apiLikeStory = (props: {storyId: number}): Promise<AxiosResponse<ApiResponse>> => {
	return axios.post(`/story/${props.storyId}/like`, {storyId: props.storyId});
};

export const apiUnlikeStory = (props: {storyId: number}): Promise<AxiosResponse<ApiResponse>> => {
	return axios.post(`/story/${props.storyId}/unlike`, {storyId: props.storyId});
};