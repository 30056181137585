import React from "react";
import {Modal} from "react-bootstrap";
import {ModalShowProps} from "app/types";

type YesNoModalProps = ModalShowProps & {
	text: string,
	handleYes: Function,
	handleNo?: Function
}

export const YesNoModal = ({show, setShow, text, handleYes, handleNo}: YesNoModalProps) => {

	const handleClose = () => setShow(false);

	return (
		<Modal
			show={show}
			onHide={handleClose}
		>
			<Modal.Header closeButton className="border-none" />
			<Modal.Body>
				<div className="text-center">
					<span>{text}</span>
				</div>
			</Modal.Body>
			<Modal.Footer className="border-none">
				<div>
					<button type="button"
									className="btn btn-storychest me-2"
					        onClick={() => {
					        	handleYes();
					        	handleClose();
					        }}
					>
						Yes
					</button>
					<button type="button"
									className="btn btn-dark"
									onClick={() => {
										handleClose();
										if (handleNo !== undefined)
											handleNo();
									}}
					>
						No
					</button>
				</div>
			</Modal.Footer>
		</Modal>
	);
}