import {FormFile} from "adapters/types";
import {getUserId} from "app/localStorage";
import {DropzoneFileProp} from "../pages/stories/components/Dropzone";

export const createFormData = (jsonParams: Object, files: FormFile[], originalFiles?: DropzoneFileProp[]): FormData => {
  const userId = getUserId();
  const formData = new FormData();
  formData.append("json", JSON.stringify({
    userId: userId,
    ...jsonParams
  }));
  for (const file of files)
    formData.append(file.name, file.content, file.name);
  console.log(originalFiles);
  if (originalFiles !== undefined) {
    for (const file of originalFiles)
      // https://developer.mozilla.org/en-US/docs/Web/API/FormData/append
      formData.append("originalFiles", file.htmlFile, file.htmlFile.name);
  }
  return formData;
}
