import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {apiGetGrapevine, apiLikeStory, apiUnlikeStory} from "adapters/grapevine";
import {HeartsLoader} from "components/HeartsLoader";
import debounce from "lodash.debounce";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {RootState} from "app/store";
import {
	clearGrapevines,
	setGrapevines,
	setLoadedGrapevines,
	updateGrapevineByIndex
} from "pages/grapevine/context/grapevineSlice";
import styles from "styles/grapevine/grapevine.module.scss";
import {DateItem} from "components/DateItem";
import {apiGetStoryDetails} from "adapters/stories";
import {
	CharacterTreeType
} from "adapters/types";
import ImageGallery from "react-image-gallery";
import Loader from "react-loader-spinner";
import {CharactersModal} from "../../components/CharactersModal";
import {useMutation, useQuery} from "react-query";
import {LeftNav, RightNav} from "components/image_gallery/Controls";

export const Grapevine = () => {
	const dispatch = useAppDispatch();

	/** Used to show stories of specific character. */
	const {characterId} = useParams<{characterId: string | undefined}>();
	
	const userData = useAppSelector((state: RootState) => state.auth.userData);

	const grapevines = useAppSelector((state: RootState) => state.grapevine.grapevines);
	const numLoadedGrapevines = useAppSelector((state: RootState) => state.grapevine.loadedGrapevines);
	const grapevinesPerPage = useAppSelector((state: RootState) => state.grapevine.grapevinesPerPage);

	const [reRender, setReRender] = useState(false);
	const forceUpdate = () => setReRender(!reRender);

	const [shownTaggedCharacters, setShownTaggedCharacters] = useState<CharacterTreeType[]>([]);
	const [loading, setLoading] = useState(true);

	const [showCharactersModal, setShowCharactersModal] = useState<boolean>(false);
	
	useQuery("getGrapevine", apiGetGrapevine, {
		onSuccess: (response) => {
			dispatch(setGrapevines({
				grapevines: response.data.Content,
				selectOnlyWithCharacterId: characterId === undefined ? undefined : parseInt(characterId)})
			);
			setLoading(false);
		}
	});
	
	const { mutate: getStoryDetailsMutate } = useMutation(
		(props: {grapevineIndex: number}) => apiGetStoryDetails({storyId: grapevines[props.grapevineIndex].Id}), {
			onSuccess: (response, variables) => {
				console.log(response);
				const grapevine = {...grapevines[variables.grapevineIndex]};
				grapevine.Details = response.data.Content;
				dispatch(updateGrapevineByIndex({index: variables.grapevineIndex, grapevineRow: grapevine}));
			}
		}
	);

	const likeStoryMutation = useMutation(apiLikeStory);
	const unlikeStoryMutation = useMutation(apiUnlikeStory);
	
	useEffect(() => {
		return () => {
			console.log("clear");
			dispatch(clearGrapevines());
		}
	}, [dispatch]);

	/** Load (story) details to each loaded grapevine. */
	useEffect(() => {
		// grapevines querying can be in-progress
		console.log(numLoadedGrapevines);
		if (grapevines.length === 0) {
			console.log("querying in-progress")
			return;
		}
		for (let i = numLoadedGrapevines - grapevinesPerPage; i < Math.min(numLoadedGrapevines, grapevines.length); i++) {
			getStoryDetailsMutate({grapevineIndex: i});
		}
	}, [getStoryDetailsMutate, grapevines.length, grapevinesPerPage, numLoadedGrapevines]);

	/** Infinity scrolling. */
	window.onscroll = debounce(() => {
		if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 300) {
			if (numLoadedGrapevines <= grapevines.length)
				dispatch(setLoadedGrapevines(numLoadedGrapevines + grapevinesPerPage));
		}
	}, 100);

	const handleLikeUnlikeClick = (grapevineIndex: number) => {
		const oldGrapevine = {...grapevines[grapevineIndex]};
		let grapevine = {...grapevines[grapevineIndex]};
		grapevine.Likes = grapevine.Likes + (grapevine.UserHasLiked ? -1 : 1);
		grapevine.UserHasLiked = !grapevine.UserHasLiked;
		dispatch(updateGrapevineByIndex({index: grapevineIndex, grapevineRow: grapevine}));
		if (oldGrapevine.UserHasLiked)
			unlikeStoryMutation.mutate({storyId: grapevine.Id});
		else
			likeStoryMutation.mutate({storyId: grapevine.Id});
	}

	return (
		<>
			<div className={`${styles.grapevineContainer} container-fluid content-wrapper content-wrapper-top-double-spacing text-center`}>
				<div className="row">
					<header className="justify-content-center text-center wrapper-icon content-wrapper-header">
						<span className="icon-grapevine headline-icon"/>
						<h1>{characterId === undefined
									? "Grapevine"
									: `${userData!.characters.find((ch) => ch.Id === parseInt(characterId))?.FirstName}'s stories`}
						</h1>
					</header>
				</div>

				{loading && <HeartsLoader/>}

				<div className="d-flex flex-column align-items-center">

					{/* TODO: grapevine item component */}

					{grapevines.length === 0 && !loading
					? <b>Nothing found.</b>
					: grapevines.slice(0, numLoadedGrapevines).map((grapevine, i) => { return (

						<section key={`grapevine-${grapevine.Id}-${i}`} className={`${styles.grapevineItem}`}>
							{ ( !grapevine.hasOwnProperty("Details") &&
		            <aside className={`${styles.grapevineImage} bg-image`}
		                   style={{backgroundImage: `url(${grapevine.CoverImage})`}}>
		              <div className={`d-flex h-100 justify-content-center align-items-end`}>
		                <Loader type="ThreeDots" color={"white"} width={45} height={35}/>
		              </div>
		            </aside>
								) || ( grapevine.Details !== undefined &&
		            <ImageGallery
			            items={[
			              {original: grapevine.CoverImage, originalClass: styles.grapevineImage},
				            ...grapevine.Details.MediaFiles.map((mediaFile) => {
				              return {
					            original: mediaFile.MainUrl,
					            originalClass: styles.grapevineImage
				            }})
									]}
		              showBullets={false}
		              showPlayButton={false}
		              disableKeyDown={true}
		              showFullscreenButton={true}
	                onScreenChange={(isFullscreenOn) => { /* TODO */ }}
			            renderLeftNav={(onClick, disabled) =>
				            <LeftNav onClick={onClick} disabled={disabled} />
			            }
	                renderRightNav={(onClick, disabled) =>
				            <RightNav onClick={onClick} disabled={disabled} />
			            }
		            />
								)
							}

							<main className={`${styles.grapevineContentWrapper} d-flex flex-column text-start`}>

								<nav className={`${styles.grapevineContentNavbar}`}>
									<div className={`${styles.likesWrapper} wrapper-icon cursor-pointer align-self-center`}
									     onClick={(e) => handleLikeUnlikeClick(i)}
									>
										<span className={`${grapevine.UserHasLiked ? "icon-heart" : "icon-heart1"}`}/>
										<span className={`${styles.likes} ms-1`}>{grapevine.Likes}</span>
									</div>
								</nav>

								<section className={`${styles.grapevineMainContent} pe-4 ps-4 flex-grow-1`}>
									<div className={`d-flex align-items-center`}>
										<div className={`${styles.authorImage} bg-image rounded-circle`}
										     style={{backgroundImage: `url(${grapevine.Author.ProfileThumbnail})`}}
										/>
										<div className="ms-2">
											<span className="storychest-color">{grapevine.Author.FirstName} {grapevine.Author.LastName}</span>
											<span> shared with you:</span>
										</div>
									</div>
									<div className="mt-3">
										<h2 className="storychest-color">{grapevine.Headline}</h2>
									</div>
									<div className="d-flex align-items-center mt-1">
										<DateItem dateTxt={grapevine.CustomDate.DateTxt}/>
										{grapevine.hasOwnProperty("Details") && grapevine.Details !== undefined && grapevine.Details.Location !== "" &&
		                  <div className={`wrapper-icon ms-2`}>
		                    <span className={`icon-navigation`}/>
		                    <span className={`ms-1`}>{grapevine.Details.Location}</span>
		                  </div>
										}
									</div>
									<div className="mt-3">
										<p className={`text-black text-justify ${styles.grapevineDescription}`}>{grapevine.Description}</p>
									</div>
								</section>

								<footer className={`d-inline-flex justify-content-between pe-4 ps-4 pb-4`}>
									{ !grapevine.hasOwnProperty("Details") && <HeartsLoader width={75} height={30}/>}
									{grapevine.hasOwnProperty("Details") && grapevine.Details !== undefined &&
									grapevine.Details.CharactersTagged.length > 0 &&
	                <div className="wrapper-icon cursor-pointer"
	                     onClick={() => {
	                     	setShowCharactersModal(true);
	                     	setShownTaggedCharacters(grapevine.Details!.CharactersTagged);
	                     }}
	                >
	                  <span className="icon-user storychest-color"/>
	                  <span className="ms-2 storychest-color">
			                  {grapevine.Details.CharactersTagged[0].FirstName + ' ' + grapevine.Details.CharactersTagged[0].LastName}
		                  </span>
										{grapevine.Details.CharactersTagged.length > 1 &&
	                  <span className="storychest-color">, +
											{grapevine.Details.CharactersTagged.length === 2 ? "1 other" :
												`${grapevine.Details.CharactersTagged.length - 1} others`}
			                  </span>
										}
	                </div>
									}
									{grapevine.hasOwnProperty("Details") && grapevine.Details !== undefined &&
	                <div className="d-flex">
										{grapevine.Details.Labels.map((label) =>
											<div key={`grapevine-${grapevine.Id}-label-${label.Id}`} className="wrapper-icon me-2">
												<span className="icon-tag storychest-color"/>
												<span className="ms-1 storychest-color">{label.Title}</span>
											</div>
										)}
	                </div>
									}
								</footer>
							</main>
						</section>

					)})}
				</div>
			</div>

			<CharactersModal show={showCharactersModal} setShow={setShowCharactersModal}
											 characters={shownTaggedCharacters} setCharacters={setShownTaggedCharacters}
			                 pickCharacters={false} forceUpdate={forceUpdate} leftRightCharactersSwitching={false}
			                 modalTitle="Tagged characters" allowSearch={false}
			/>
		</>
	)
}