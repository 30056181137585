import {
  FilterStoriesParamsType,
  ApiLabelsResponseType,
  ApiStoriesResponseType,
  ApiStoriesYearsMonthsResponseType,
  ApiStoryDetailsResponseType,
  ApiResponse,
  AddEditStoryParamsType,
  ApiAddEditStoryResponseType, FormFile
} from "./types";
import {createFormData} from "../helpers/api";
import {axiosProvider as axios} from "../app/axiosProvider";
import {AxiosResponse} from "axios";
import {DropzoneFileProp} from "../pages/stories/components/Dropzone";

export const apiGetStories = (): Promise<AxiosResponse<ApiStoriesResponseType>> => {
  return axios.post("/get-stories");
};

export const apiGetFilteredStories = (props: FilterStoriesParamsType): Promise<AxiosResponse<ApiResponse>> => {
  return axios.post(
    "/get-filtered-stories",
    {
      startDate: props.startDate,
      endDate: props.endDate,
      searchTxt: props.searchTxt,
      locationTxt: props.locationTxt,
      labels: props.labels,
      charactersTagged: props.charactersTagged
    }
  );
};

export const apiGetStoriesByYearMonth = (props: {yearId: number, monthId: number}): Promise<AxiosResponse<ApiResponse>> => {
  return axios.post(`/filter-stories/${props.yearId}/${props.monthId}`, {yearId: props.yearId, monthId: props.monthId});
};

export const apiGetLabels = (): Promise<AxiosResponse<ApiLabelsResponseType>> => {
  return axios.post("/get-labels");
};

export const apiAddLabel = (props: {label: string}): Promise<AxiosResponse<ApiResponse>> => {
  return axios.post("/labels/add", {labelTitle: props.label});
};

export const apiGetStoriesYearsMonths = (): Promise<AxiosResponse<ApiStoriesYearsMonthsResponseType>> => {
  return axios.post("/get-stories-years-months");
};

export const apiGetStoryDetails = (props: {storyId: number}): Promise<AxiosResponse<ApiStoryDetailsResponseType>> => {
  console.log("api get grapevine item ", props.storyId);
  return axios.post(`/story/${props.storyId}/details`, {storyId: props.storyId});
};

export const apiDeleteStory = (props: {storyId: number}): Promise<AxiosResponse<ApiResponse>> => {
  return axios.post(`/story/${props.storyId}/delete`, {storyId: props.storyId});
};

export interface ApiStoryProps {
  story: AddEditStoryParamsType,
  files: FormFile[],
  originalFiles: DropzoneFileProp[]
}

export const apiAddStory = (props: ApiStoryProps): Promise<AxiosResponse<ApiAddEditStoryResponseType>> => {
  const formData = createFormData(props.story, props.files, props.originalFiles);
  return axios.post("/story/add", formData, {
    headers: {"Content-Type": "multipart/form-data"}
  });
};

export const apiEditStory = (props: ApiStoryProps): Promise<AxiosResponse<ApiAddEditStoryResponseType>> => {
  const formData = createFormData(props.story, props.files, props.originalFiles);
  return axios.post("/story/edit", formData, {
    headers: {"Content-Type": "multipart/form-data"}
  });
};