import axios, {AxiosResponse} from "axios";
import {ApiLoginResponse, ApiResponse} from "./types";
import config from "config";
import {getAndSetIfNullDeviceId} from "../app/localStorage";

export interface Credentials {
  email: string,
  password: string
}

export const apiLoginUser = ({email, password}: Credentials): Promise<AxiosResponse<ApiLoginResponse>> => {
  return axios.post(`${config.API_URL}/login`, {
    email: email,
    password: password,
    deviceId: getAndSetIfNullDeviceId()
  }, {
    validateStatus: (status) => status === 200}
  );
}

export const apiForgotPassword = ({email}: {email: string}): Promise<AxiosResponse<ApiResponse>> => {
  return axios.post(`${config.API_URL}/forgot-password`, {email: email}, {
    validateStatus: (status) => status === 200}
  );
}