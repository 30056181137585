import createStoryStyles from "../styles/stories/create_story.module.scss";
import React from "react";
import {useAppSelector, UsePickGroupsReturnType} from "../app/hooks";
import {RootState} from "../app/store";
import {Accordion} from "react-bootstrap";

export type PickGroupsProps = UsePickGroupsReturnType & {
	showAccordionBody?: boolean
}

export const PickGroups = ({sharedGroups, setSharedGroups, showAccordionBody = false}: PickGroupsProps) => {

	const userData = useAppSelector((state: RootState) => state.auth.userData);

  return (
	  <Accordion className={`${createStoryStyles.inputsGroup}`} defaultActiveKey={showAccordionBody ? ['0'] : []} alwaysOpen={showAccordionBody}>
		  <Accordion.Item eventKey="0">
			  <Accordion.Header className="font-lato"><div className="flex-grow-1 text-center">Groups</div></Accordion.Header>
			  <Accordion.Body className="d-flex flex-column align-items-center">
				  { userData?.groups.map((group, i) => { return (
					  <div key={`group-${i}`} className={`${createStoryStyles.inputsGroup} w-75`}>
						  <button
							  className={
							  	`btn
							  	${sharedGroups.get(group.Id) ? createStoryStyles.active : ""} ${createStoryStyles.shareStoryWithButton}`
							  }
							  type="button"
							  onClick={() => {
								  const newSharedGroups = new Map(sharedGroups);
								  newSharedGroups.set(group.Id, !sharedGroups.get(group.Id));
								  setSharedGroups(newSharedGroups);
							  }}
						  >
							  {group.Title}
						  </button>
					  </div>
				  )}) }
			  </Accordion.Body>
		  </Accordion.Item>
	  </Accordion>
  )
}