import styles from "styles/overlay/overlay.module.scss";
import React from "react";

interface OverlayProps {
	children: React.ReactNode
}

export const Overlay = ({children}: OverlayProps) => {
	return (
		<div className={`${styles.overlay}`}>
			{children}
		</div>
	)
}