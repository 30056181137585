import React, {useState} from "react";
import {DropdownScrollOnlyMenu} from "components/DropdownScrollMenu";
import {apiGetAlerts} from "adapters/navbar";
import {Link} from "react-router-dom";
import {ApiAlertResponseContentType, ApiAlertsResponseContentType} from "adapters/types";
import styles from "styles/alerts.module.scss";
import navStyles from "styles/navbar.module.scss";
import defaultCharacterImage from "static/default_character_image.jpg";
import {HeartsLoader} from "./HeartsLoader";
import {Dropdown} from "react-bootstrap";
import {useQuery} from "react-query";
import parse from "html-react-parser";

export const Alerts = () => {
	const [alerts, setAlerts] = useState<ApiAlertsResponseContentType>({Alerts: []});

	const {isLoading} = useQuery("getAlerts", apiGetAlerts, {
		onSuccess: (response) => {
			setAlerts(response.data.Content);
		}
	});

	return (
		<Dropdown>
			<Dropdown.Toggle id="alertsDropdownMenu" className={`${navStyles.navigationIconRounded}`} variant="Link">
				<span className="icon-bell text-white" />
			</Dropdown.Toggle>
			<DropdownScrollOnlyMenu menuId="alerts" className={styles.alerts}>
				{isLoading
					?
						<div className="d-flex w-100 h-100 justify-content-center align-items-center">
							<HeartsLoader/>
						</div>
					:
						alerts.Alerts.length === 0
							?
								<div className="w-100 h-100 d-flex justify-content-center align-items-center">
									You do not have any alerts yet.
								</div>
							:
								alerts.Alerts.map((alert, i) => {
									return (
										<AlertItem key={`alert-${i}`} alert={alert} last={i === alerts.Alerts.length - 1}/>
									)
								})
				}
			</DropdownScrollOnlyMenu>
		</Dropdown>
	)
}

interface AlertItemProps {
	alert: ApiAlertResponseContentType,
	last: boolean
}

const AlertItem = ({alert, last}: AlertItemProps) => {
	const alertText = alert.Text.replace(
		"<font ",
		`<font className='${styles.alertSentByName} dropdown-item-active-text-black' `
	);

	return (
		<Dropdown.Item as={Link} to={alert.Story !== null ? `/story/${alert.Story.Id}/details` : "#"}
		               className={`d-flex flex-column text-start pt-2 pb-2`}
		>
			<div className="d-flex align-items-center">
				{alert.SentBy === null
					? <></>
					: <div className={`bg-image ms-3 rounded-circle ${styles.alertImage}`}
								 style={{backgroundImage: `url("${alert.SentBy.ProfileThumbnail}"), url(${defaultCharacterImage})`}}/>
				}
				<div className={`ms-3 me-3 text-justify ${styles.alertText}`}>
					<span>{parse(alertText)}</span>
				</div>
			</div>
			<div className="text-end">
				<small>{new Date(alert.DateAdded).toLocaleString("en-GB", {hour12: true})}</small>
			</div>
		</Dropdown.Item>
	)
}