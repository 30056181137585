import React, {useState} from "react";
import {useAppDispatch, useAppSelector, usePickDate, usePickGroups} from "app/hooks";
import {AddCharacterParamsType, CharacterTreeType} from "adapters/types";
import {apiAddCharacter, apiEditCharacter, apiEditUser} from "adapters/characters";
import {ProfileImage} from "components/forms/profile_image/ProfileImage";
import {PickDate} from "components/PickDate";
import {PickGroups} from "components/PickGroups";
import {RootState} from "app/store";
import createSnackbar, {SnackTypes} from "../../../components/snackbar/Snackbar";
import {SetShowModal} from "app/types";
import {InputLine} from "../../../components/forms/InputLine";
import {useMutation} from "react-query";
import {addOrEditCharacterToUserData, editProfileInUserData} from "../../authorization/context/authSlice";

interface CreateEditCharacterFormProps {
	character?: CharacterTreeType,
	setShow?: SetShowModal,
	editGroups?: boolean,
	editingProfile?: boolean
}

export const CreateEditCharacterForm = (
	{character, setShow, editGroups=false, editingProfile=false}: CreateEditCharacterFormProps
) => {

	const editingCharacter: boolean = character !== undefined;

	const dispatch = useAppDispatch();

	const userData = useAppSelector((state: RootState) => state.auth.userData)!;

	/** Character's profile image. */
	const [file, setFile] = useState<File | undefined>();

	const [email, setEmail] = useState<string>(character?.Email || "");
	const [firstName, setFirstname] = useState<string>(character?.FirstName || "");
	const [lastName, setLastName] = useState<string>(character?.LastName || "");

	const dateState = usePickDate(
		character?.CustomDate.DateYearPart || undefined,
		character?.CustomDate?.DateMonthPart || undefined,
		character?.CustomDate.DateDayPart || undefined
	);
	const groupsState = usePickGroups(
		new Map(userData?.groups.map(group => [group.Id, character?.GroupsIds.some(groupId => groupId === group.Id) || false]))
	);

	const [saveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(false);

	const apiCallOnSubmit = editingProfile ? apiEditUser : editingCharacter ? apiEditCharacter : apiAddCharacter;

	const mutation = useMutation(apiCallOnSubmit, {
		onSuccess: (response) => {
			if (response.status !== 200) {
				createSnackbar(response.data.Message, SnackTypes.error);
				return;
			}
			if (!editingCharacter && setShow)
				setShow(false);
			if (editingProfile) {
				const imageURL: string | undefined = (file === undefined) ? undefined : URL.createObjectURL(file);
				dispatch(editProfileInUserData({
					userId: userData.userId, firstName: firstName, lastName: lastName, email: email,
					birthday: {
						DateTxt: "",
						DateYearPart: dateState.selectedYear,
						DateMonthPart: dateState.selectedMonth === 0 ? undefined : dateState.selectedMonth,
						DateDayPart: dateState.selectedDay === 0 ? undefined : dateState.selectedDay,
					},
					profileImageURL: imageURL
				}))
			}
			else
				dispatch(addOrEditCharacterToUserData(response.data.Content));
			createSnackbar(editingCharacter ? "Changes saved!" : "New character has been created!", SnackTypes.success);
		},
		onError: () => {
			createSnackbar("Something went wrong.", SnackTypes.error);
		},
		onSettled: () => {
			setSaveBtnDisabled(false);
		}
	});

	/** Send request to create new character. */
	const handleSubmit = () => {
		setSaveBtnDisabled(true);

		// prepare data
		const form: AddCharacterParamsType = {
			email: email,
			firstName: firstName,
			lastName: lastName,
			dateyearpart: dateState.selectedYear.toString(),
			datemonthpart: dateState.selectedMonth === 0 ? "" : dateState.selectedMonth.toString(),
			datedaypart: dateState.selectedDay === 0 ? "" : dateState.selectedDay.toString(),
			groupsIds: [...groupsState.sharedGroups.entries()].filter(value => value[1]).map(value => value[0])
		}

		// (edit character) params
		if (editingCharacter) {
			form.characterId = character!.Id;
		}

		// profile image file
		const files = [];
		if (file !== undefined) {
			files.push({name: "profileImage", content: file});
			files.push({name: "profileImage_thumbnail", content: file});
		}

		mutation.mutate({character: form, files: files});
	}

	return (<>
		<div className="row justify-content-center">
			<div className="col-10">
				<form method="post" encType="multipart/form-data" onSubmit={(e) => e.preventDefault()}>

					<div className={`d-flex justify-content-center g-2 mb-3`}>
						{ <ProfileImage setFile={setFile} provideUpload={true} imageURL={character?.ProfileThumbnail} /> }
					</div>

					<InputLine id="email" type="email" value={email}
					           onChange={(e) => setEmail(e.target.value)} />
					<InputLine id="first_name" value={firstName} placeholder="First name"
					           onChange={(e) => setFirstname(e.target.value)} />
					<InputLine id="last_name" value={lastName} placeholder="Last name"
					           onChange={(e) => setLastName(e.target.value)} />

					<div className={`text-start mb-1`}>
						<span className={`font-breelight`} style={{fontSize: "1.2em"}}>Birthday</span>
					</div>
					<div className={`mb-4`}>
						<PickDate {...dateState} />
					</div>

					{editGroups &&
	          <div className={`mb-4`}>
	            <PickGroups {...groupsState} />
	          </div>
					}

					<button
						className={`btn btn-storychest text-center`}
						onClick={handleSubmit}
						disabled={saveBtnDisabled}
					>
						{ editingCharacter ? "Save changes" : "Create" }
					</button>
				</form>
			</div>
		</div>
	</>)
}