import {ApiLoginResponseContent} from "adapters/types";
import {UserDataType} from "./types";
import {getRandomString, orderInPlaceCharacters} from "../helpers/global";

export const setUserData = (userData: ApiLoginResponseContent, setTokens: boolean = true) => {
  // add logged-in user character to his all characters
  userData.CharacterTree.push({
    CustomDate: userData.CustomDate,
    Email: userData.Email,
    Id: userData.Id,
    FirstName: userData.FirstName,
    LastName: userData.LastName,
    ProfileThumbnail: userData.ProfileThumbnail,
    ProfileImage: userData.ProfileImage,
    IsStorychestUser: userData.Subscribed,
    IsInUserTree: true,
    GroupsIds: []
  });
  orderInPlaceCharacters(userData.CharacterTree);
  const _userData: UserDataType = {
    userId: userData.Id,
    characters: userData.CharacterTree,
    groups: userData.AllGroups,
    characterIndex: userData.CharacterTree.findIndex(ch => ch.Id === userData.Id)
  };
  localStorage.setItem("userData", JSON.stringify(_userData));
  if (setTokens)
    setToken(userData.AccessToken, userData.RefreshToken);
};

export const setToken = (accessToken: string, refreshToken: string) => {
  localStorage.setItem("accessToken", JSON.stringify(accessToken));
  localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
};

export const updateUserData = (userData: UserDataType) => {
  orderInPlaceCharacters(userData.characters);
  userData.characterIndex = userData.characters.findIndex(ch => ch.Id === userData.userId)
  localStorage.setItem("userData", JSON.stringify(userData));
}

export const getUserData = (): UserDataType | undefined => {
  const userData = localStorage.getItem("userData");
  return userData === null ? undefined : JSON.parse(userData);
};

export const clearUserData = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

export const getAccessToken = (): string | undefined => {
  const accessToken = localStorage.getItem("accessToken");
  return accessToken === null ? undefined : JSON.parse(accessToken);
};

export const getRefreshToken = (): string | undefined => {
  const refreshToken = localStorage.getItem("refreshToken");
  return refreshToken === null ? undefined : JSON.parse(refreshToken);
};

export const getUserId = (): number | undefined => {
  const userData = getUserData();
  return userData === undefined ? undefined : userData.userId;
};

export const isUserLoggedIn = (): boolean => {
  return getAccessToken() !== undefined;
};

export const getAndSetIfNullDeviceId = (): string => {
  let deviceId: string | null = localStorage.getItem("deviceId");
  if (deviceId === null) {
    deviceId = getRandomString(32);
    localStorage.setItem("deviceId", deviceId);
  }
  return deviceId;
}