import {Link, useHistory} from "react-router-dom";
import React, {memo, useEffect, useState} from "react";
import {DateItem} from "components/DateItem";
import {StoryLabel} from "./StoryLabel";
import {StoryCharacter} from "./StoryCharacter";
import {HeartsLoader} from "components/HeartsLoader";
import {useStoryDetails, UseStoryDetailsReturnType} from "../../../app/hooks";
import {Modal} from "react-bootstrap";
import {ModalShowProps} from "app/types";
import {StoryImageGallery} from "./StoryImageGallery";

type PreviewStoryModalProps = ModalShowProps & {
	storyId: number
}

export const PreviewStoryModal = ({show, setShow, storyId}: PreviewStoryModalProps) => {
	const history = useHistory();

	const [loading, setLoading] = useState(true);

	const {storyDetails, setStoryDetails, images, setImages}: UseStoryDetailsReturnType = useStoryDetails(storyId);

	useEffect(() => {
		if (storyDetails !== undefined)
			setLoading(false);
	}, [storyDetails]);

	const handleOpen = () => {
		if (storyDetails?.Id !== storyId) {
			setStoryDetails(undefined);
			setImages([]);
			setLoading(true);
		}
	}
	const handleClose = () => setShow(false);

	return (
		<Modal
			show={show}
			onHide={handleClose}
			onShow={handleOpen}
			centered
		>
			<Modal.Header className="border-none flex-column">
				<div className="w-100 d-flex justify-content-between">
					<div className="wrapper-icon modal-title" id="previewStoryLabel">
						<span className="icon-book storychest-color pe-2"/>
						<h5 className="pe-1 text-left">{storyDetails?.Headline}</h5>
					</div>
					<div>
						<Link to={`/story/${storyId}/details`} data-dismiss="modal" aria-label="Close"
						      onClick={(e) => history.push(`/story/${storyId}/details`)}>
							<span className="icon-magnifier_with_bars cursor-pointer text-black"/>
						</Link>
					</div>
				</div>
				<div className="w-100 d-flex flex-column align-items-start mt-3">
					<div className="d-flex flex-wrap">
						<DateItem dateTxt={storyDetails === undefined ? "" : storyDetails.CustomDate.DateTxt}/>
						{storyDetails !== undefined && storyDetails.Labels.map((label) => {
							return (
								<StoryLabel key={`label-preview-story-${label.Id}`} label={label.Title} className="ms-2"/>
							)
						})}
					</div>
					{storyDetails?.Location !== "" &&
          <div className="mt-2 wrapper-icon">
            <span className="icon-navigation me-1"/>
            <span>{storyDetails?.Location}</span>
          </div>
					}
					<div className="d-flex align-items-center flex-wrap mt-1">
						{storyDetails !== undefined && storyDetails.CharactersTagged.map((character) => {
							return (
								<StoryCharacter key={`character-preview-story-${character.Id}`} character={character} className="me-2 mt-1"/>
							)
						})}
					</div>
				</div>
			</Modal.Header>
			<Modal.Body className="p-0">
				{loading
					? <div className="text-center"><HeartsLoader /></div>
					: storyDetails !== undefined && storyDetails.MediaFiles.length > 0 && <StoryImageGallery images={images} />
				}
				<div className="text-justify mb-1 p-3">
					{storyDetails?.Description}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button type="button" className="btn btn-outline-secondary" onClick={handleClose}>Close</button>
			</Modal.Footer>
		</Modal>
	);
}