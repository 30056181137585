import {Link, NavLink} from "react-router-dom";
import styles from "../../styles/navbar.module.scss";
import {useAppSelector, useCharacterDetails} from "../../app/hooks";
import {RootState} from "../../app/store";
import {useState} from "react";
import {Alerts} from "../Alerts";
import {Dropdown} from "react-bootstrap";
import {CharacterTreeType} from "../../adapters/types";

export const Navbar = () => {
  const sidebarActive = useAppSelector((state: RootState) => state.navbar.sidebarActive);
  const isUserLoggedIn = useAppSelector((state: RootState) => state.auth.isUserLoggedIn);

  const [openedNavigationOverlay, toggleNavigationOverlay] = useState(false);

  return (
    <>
      <nav className={`navbar ${styles.navbar} ${styles.navbarExpandCustom} fixed-top ${isUserLoggedIn ? "" : styles.navLogout}`}>
        <div className="container-fluid" id={styles.navbarContainer}>
          <div id={styles.leftSide}>
            <a href="https://web.storychest.com" className="navbar-brand" target="_blank">
              <span className="icon-StorychestLogo text-white"/>
            </a>
          </div>

          {isUserLoggedIn &&
            <span className={`menu-icon-bars navbar-toggler-icon navbar-toggler ${styles.navbarToggler} ${styles.navbarMenuButton} text-white
                  ${openedNavigationOverlay ? "icon-cross" : "icon-bars"}`} data-target={styles.navbarResponsive}
                  aria-controls={styles.navbarResponsive} aria-expanded="false" aria-label="Toggle navigation"
                  onClick={(e) => toggleNavigationOverlay(!openedNavigationOverlay)}/>
          }

          <div className={`collapse navbar-collapse ${styles.navbarCollapse} ${sidebarActive ? styles.sidebarActive : ""}`} id={styles.navbarResponsive}>

            <NavbarMiddleSide isUserLoggedIn={isUserLoggedIn} />
            <NavbarRightSide isUserLoggedIn={isUserLoggedIn}/>

          </div>
        </div>
      </nav>

      <div className={`${styles.menuOverlay} ${openedNavigationOverlay ? "d-flex active" : "d-none"}`}>
        <div className="container">
          <NavLinkRow linkTo={"/stories"} iconClassName={"icon-book"} LinkText={"Stories"} toggleNavigationOverlay={toggleNavigationOverlay}/>
          <NavLinkRow linkTo={"/grapevine"} iconClassName={"icon-grapevine"} LinkText={"Grapevine"} toggleNavigationOverlay={toggleNavigationOverlay}/>
          <NavLinkRow linkTo={"/characters"} iconClassName={"icon-users"} LinkText={"Characters"} toggleNavigationOverlay={toggleNavigationOverlay}/>
          <NavLinkRow linkTo={"/profile"} iconClassName={"icon-user"} LinkText={"Profile"} toggleNavigationOverlay={toggleNavigationOverlay}/>
        </div>
      </div>
    </>
  );
};

interface NavbarMiddleSideProps {
  isUserLoggedIn: boolean
}

const NavbarMiddleSide = ({isUserLoggedIn}: NavbarMiddleSideProps) => {
  if (isUserLoggedIn) {
    return (
      <ul className={`nav ${styles.navMiddle}`}>
        <li className={`nav-item ${styles.navItem}`}>
          <NavLink className={`nav-link ${styles.navLink}`} to="/stories" activeClassName={styles.active}>
            <p><span className={`icon-book ${styles.iconBook}`}/></p>
            <span>Stories</span>
          </NavLink>
        </li>
        <li className={`nav-item ${styles.navItem}`}>
          <NavLink className={`nav-link ${styles.navLink}`} to="/grapevine" activeClassName={styles.active}>
            <p><span className={`icon-grapevine ${styles.iconGrapevine}`}/></p>
            <span>Grapevine</span>
          </NavLink>
        </li>
        <li className={`nav-item ${styles.navItem}`}>
          <NavLink className={`nav-link ${styles.navLink}`} to="/characters" activeClassName={styles.active}>
            <p><span className={`icon-users ${styles.iconUsers}`}/></p>
            <span>Characters</span>
          </NavLink>
        </li>
      </ul>
    );
  }
  return <></>
}

interface NavbarRightSideProps {
  isUserLoggedIn: boolean
}

const NavbarRightSide = ({isUserLoggedIn}: NavbarRightSideProps) => {

  const character: CharacterTreeType | undefined = useCharacterDetails();

  if (!isUserLoggedIn)
    return <></>;

  return (
    <ul className={`nav ${styles.navRight} align-items-center`} id={styles.rightSide}>
      <Link to="/profile" className={`d-flex align-items-center ${styles.profileItem}`}>
        <div className="bg-image rounded-circle me-1"
              style={{backgroundImage: `url("${character?.ProfileThumbnail}"), url("${character?.ProfileImage}")`,
                      width: "38px", height: "38px"}}
        />
        <span className="text-white">{character?.FirstName}</span>
      </Link>
      <Alerts/>
      <Dropdown>
        <Dropdown.Toggle id="navbarDropdownMenu" className={`rotated-90-icon-wrapper ${styles.navigationIconRounded} me-2`} variant="Link">
          <span className={`icon-play ${styles.iconPlay} text-white rotated-90-icon`}/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/profile" className={styles.dropdownItem}>
            <div className={`wrapper-icon ${styles.wrapperIcon}`}>
              <span className={`icon-user pe-2 storychest-color`} />
              <span>Profile</span>
            </div>
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/logout">
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </ul>
  );
}

interface NavLinkRowProps {
  linkTo: string,
  iconClassName: string,
  LinkText: string,
  toggleNavigationOverlay: Function
}

const NavLinkRow = ({linkTo, iconClassName, LinkText, toggleNavigationOverlay}: NavLinkRowProps) => {
  return (
    <div className="row">
      <div className="col-12 link-wrapper">
        <Link to={linkTo} onClick={(e) => toggleNavigationOverlay()}>
          <div className="wrapper-icon justify-content-center">
            <span className={`${iconClassName} text-white pe-3`}/>
            <span className="text-white">{LinkText}</span>
          </div>
        </Link>
      </div>
    </div>
  )
}