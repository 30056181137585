import React from "react";
import defaultCharacterImage from "static/default_character_image.jpg";
import {CharacterTreeType} from "../../../adapters/types";

interface StoryCharacterProps {
	character: CharacterTreeType,
	className?: string
}

export const StoryCharacter = ({character, className}: StoryCharacterProps) => {
	return (
		<div className={`d-flex align-items-center ${className === undefined ? "" : className}`}>
			<div className={`bg-image rounded-circle me-1`}
			     style={{backgroundImage: `url("${character.ProfileImage}"), url(${defaultCharacterImage})`,
								   width: "30px", height: "30px"}}
			>
			</div>
			<div>
				<span>{character.FirstName} {character.LastName}</span>
			</div>
		</div>
	)
}