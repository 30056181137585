import {MouseEvent as ReactMouseEvent, memo} from "react";
import styles from "./styles/controls.module.scss";

export interface NavProps {
	disabled: boolean,
	onClick: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export interface FullscreenProps {
	isFullscreen: boolean,
	onClick: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const LeftNav = memo(({disabled, onClick}: NavProps) => {
	return (
		<button className={`${styles.imageGalleryNavButton} image-gallery-icon image-gallery-left-nav`}
						disabled={disabled}
						onClick={onClick}
						aria-label="Previous Slide"
		>
			<span className={`${styles.imageGalleryNavArrowShadow} icon-left_arrow text-white`}/>
		</button>
	);
});

export const RightNav = memo(({disabled, onClick}: NavProps) => {
	return (
		<button className={`${styles.imageGalleryNavButton} image-gallery-icon image-gallery-right-nav`}
						disabled={disabled}
						onClick={onClick}
						aria-label="Next Slide"
		>
			<span className={`${styles.imageGalleryNavArrowShadow} icon-right_arrow text-white`}/>
		</button>
	);
});

export const Fullscreen = memo(({isFullscreen, onClick}: FullscreenProps) => {
	return (
		<button
			type="button"
			className={`${styles.imageGalleryFullscreenButton} image-gallery-icon`}
			onClick={onClick}
			aria-label="Open Fullscreen"
		>
			<span className={isFullscreen ? "icon-fullscreen_exit" : "icon-fullscreen"} />
		</button>
	);
});