import {useState, ChangeEvent} from "react";
import {ApiLabelsResponseContentType} from "adapters/types";
import {apiAddLabel, apiGetLabels} from "adapters/stories";
import {capitalize} from "helpers/global";
import {ModalShowProps} from "app/types";
import {Modal} from "react-bootstrap";
import {InputButtonGroup} from "components/forms/InputButtonGroup";
import {useMutation, useQuery} from "react-query";
import createSnackbar from "../../../components/snackbar/Snackbar";

type LabelsModalProps = ModalShowProps & {
	labels: ApiLabelsResponseContentType[],
	setLabels: Function,
	checkedLabels: ApiLabelsResponseContentType[],
	setCheckedLabels: Function
}

export const LabelsModal = ({show, setShow, labels, setLabels, checkedLabels, setCheckedLabels}: LabelsModalProps) => {

	const [newLabelToAdd, setNewLabelToAdd] = useState("");
	const [newLabelToAddError] = useState("");

	useQuery("getLabels", apiGetLabels, {
		onSuccess: (response) => {
			setLabels(response.data.Content)
		},
		onError: (error) => {
			console.error(error);
		}
	});

	const mutation = useMutation(apiAddLabel, {
		onSuccess: (response) => {
			const newLabel = response.data.Content;
			setLabels([...labels, newLabel]);
		}
	});

	const addLabelOnClick = () => {
		if (newLabelToAdd === "") return;
		mutation.mutate({label: capitalize(newLabelToAdd)});
	}

	const handleCheckboxOnChange = (e: ChangeEvent<HTMLInputElement>, label: ApiLabelsResponseContentType) => {
		let _checkedLabels = [...checkedLabels];
		if (e.target.checked)
			_checkedLabels.push(label);
		else {
			_checkedLabels.splice(_checkedLabels.findIndex((checkedLabel) =>
				checkedLabel.Id === label.Id), 1);
		}
		setCheckedLabels(_checkedLabels);
	}

	const handleClose = () => setShow(false);

	return (
		<Modal
			show={show}
			onHide={handleClose}
		>
			<Modal.Header closeButton>
				<div className="wrapper-icon modal-title" id="labelsModalLabel">
					<h5>Your Labels</h5>
					<span className="icon-tag storychest-color ms-1"/>
				</div>
			</Modal.Header>
			<Modal.Body id="label-checkboxes-container">
				{labels.map((label, i) => { return (
					<div className="form-check" key={`label-${label.Id}`}>
						<input className="form-check-input" type="checkbox" id={`label-${label.Id}`}
						       onChange={(e) => handleCheckboxOnChange(e, label)}
						       checked={checkedLabels.some((checkedLabel) => checkedLabel.Id === label.Id)}
						/>
						<label className="form-check-label" htmlFor={`label-${label.Id}`}>{label.Title}</label>
					</div>
				)})}
			</Modal.Body>
			<Modal.Footer className="justify-content-between">
				<div className="d-flex align-items-start justify-content-center">
					<InputButtonGroup inputClassName={`form-control ${newLabelToAddError !== "" && "is-invalid"}`}
					                  placeholder="Add new label"
					                  handleChange={setNewLabelToAdd}
					                  handleClick={addLabelOnClick}
					                  value={newLabelToAdd}
					/>
					<div className="invalid-feedback">
						{newLabelToAddError}
					</div>
				</div>
				<div>
					<button type="button" className="btn btn-outline-secondary" onClick={handleClose}>Done</button>
				</div>
			</Modal.Footer>
		</Modal>
	)
}