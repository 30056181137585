import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {apiGetStoriesYearsMonths} from "../../adapters/stories";
import {RootState} from "../../app/store";
import {setStoriesYearsMonths, startLoading, stopLoading, changeSubNavigationHref, changeSubNavigationText, changeText} from "./context/storiesSlice";
import {Link, useParams} from "react-router-dom";
import {getMonthName} from "../../helpers/global";
import styles from "styles/stories/story_item.module.scss";
import {useMutation} from "react-query";

export const Months = () => {
  const dispatch = useAppDispatch();
  const storiesYearsMonths = useAppSelector((state: RootState) => state.stories.storiesYearsMonths);

  const { year } = useParams<{year: string}>();

  useEffect(() => {
    dispatch(changeText(`Stories - ${year}`));
    dispatch(changeSubNavigationText("Go back"));
    dispatch(changeSubNavigationHref("/stories/list-by-years"));
  }, [dispatch, year]);

  const mutation = useMutation(apiGetStoriesYearsMonths, {
    onSuccess: (response) => {
      dispatch(setStoriesYearsMonths(response.data.Content));
      dispatch(stopLoading());
    },
    onSettled: () => { dispatch(stopLoading()); }
  });

  useEffect(() => {
    if (storiesYearsMonths.Years.length === 0) {
      dispatch(startLoading());
      mutation.mutate();
    }
  }, [storiesYearsMonths.Years.length]);

  const storiesInYear = storiesYearsMonths.Years.find((story) => story.Title === year);

  return (
    <>
      {storiesInYear === undefined ? <h2>No stories found.</h2>
        : storiesInYear.Months.map((month, i) => { return (
        <Link to={`/stories/${year}/${month.Title}`} key={`story-${year}-${month.Title}`}>
          <figure className="figure p-4">
            <div className={styles.coverCircleBgImageContainer}>
              <div className={`rounded-circle ${styles.coverCircleBgImage}`} style={{backgroundImage: `url("${month.Cover}")`}}/>
            </div>
            <figcaption className="figure-caption text-center m-2">
              <div className="">
                <div>
                  <h2 className="storychest-color">{getMonthName(month.Title)}</h2>
                </div>
                <div>
                  <span>{month.Count} {month.Count === 1 ? "Story" : "Stories"}</span>
                </div>
              </div>
            </figcaption>
          </figure>
        </Link>
      )})}
    </>
  );
};