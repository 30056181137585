import React, {useEffect, useState} from "react";
import {apiGetFilteredStories} from "adapters/stories";
import {ApiLabelsResponseContentType, CharacterTreeType, CharacterType, FilterStoriesParamsType} from "adapters/types";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {changeText, setStories, startLoading, stopLoading} from "pages/stories/context/storiesSlice";
import defaultCharacterImage from "static/default_character_image.jpg";
import {RootState} from "app/store";
import {useHistory, useLocation} from "react-router-dom";
import {scrollToTop} from "helpers/global";
import {LabelsModal} from "./LabelsModal";
import {CharactersModal} from "components/CharactersModal";
import styles from "styles/stories/stories_sidebar.module.scss"
import {useMutation} from "react-query";
import {AddButton} from "../../../components/AddButton";
import createSnackbar, {SnackTypes} from "../../../components/snackbar/Snackbar";

// TODO: default search props and triggering search on that first load
// TODO: or new higher component - stories featuring...
// TODO: redirect with search param (route param) (character id)

type SidebarPropsType = {
	isSidebarHidden: boolean,
	defaultCheckedCharacter?: CharacterTreeType
};

export const Sidebar = (props: SidebarPropsType) => {
	const dispatch = useAppDispatch();
	const userData = useAppSelector((state: RootState) => state.auth.userData);
	const history = useHistory();

	const location = useLocation<any>();

	const [reRender, setReRender] = useState(false);
	const forceUpdate = () => setReRender(!reRender);

	const [startDate, setStartDate] = useState("");
	const [toDate, setToDate] = useState("");
	const [contain, setContain] = useState("");
	const [where, setWhere] = useState("");
	const [labels, setLabels] = useState<ApiLabelsResponseContentType[]>([]);
	const [checkedLabels, setCheckedLabels] = useState<ApiLabelsResponseContentType[]>([]);
	const [checkedCharacters, setCheckedCharacters] = useState<Map<number, CharacterType>>(new Map());
	/** Use `userData!.characters` to get all user's characters and `characters` to store and get characters displayed
	 *  in modal based on search.
	 *  All filtering must remain order of characters! */
	const [characters, setCharacters] = useState([...userData!.characters]);

	const mutation = useMutation(apiGetFilteredStories, {
		onSuccess: (response) => {
			dispatch(setStories(response.data.Content));
		},
		onSettled: () => {
			dispatch(stopLoading());
		}
	});

	const handleSearchButtonClick = (searchOnlyByFeaturingCharacter: boolean = false) => {
		scrollToTop();
		dispatch(startLoading());
		const filterParams: FilterStoriesParamsType = {
			startDate: startDate,
			endDate: toDate,
			searchTxt: contain,
			locationTxt: where,
			labels: checkedLabels.map((label) => label.Id),
			charactersTagged: Array.from(checkedCharacters.keys())
		}
		mutation.mutate(filterParams);
		if (!searchOnlyByFeaturingCharacter) {
			if (location.search !== "?filter")
				history.push({pathname: "/stories", search: "?filter"});
			dispatch(changeText(`Stories found by the filter`));
		}
	};

	/**
	 * 	Filter by query (GET) parameters.
	 * 	TODO: Currently works only for featuring character (i.e. ?characterId=123) while ?filter is used for other
	 * 	TODO: filtering. Eventually this hook will be used to fill filter states by query parameters (+ on search
	 * 	TODO: url query path will update to proper key-value structure that will be used here).
	 */
	useEffect(() => {
		const { search } = location;
		let characterId: string | number | null = new URLSearchParams(search).get("characterId");
		if (characterId !== null) {
			characterId = parseInt(characterId);
			if (isNaN(characterId)) {
				createSnackbar("Invalid character id.", SnackTypes.error);
				return;
			}
			const character: CharacterType | undefined = userData!.characters.find(ch => ch.Id === characterId);
			if (character === undefined) {
				createSnackbar("Character not found.", SnackTypes.error);
				return;
			}
			checkedCharacters.set(characterId, character);
			setCheckedCharacters(checkedCharacters);
			dispatch(changeText(`Stories featuring ${character.FirstName}`));
			handleSearchButtonClick(true);
		}
		else if (search !== "") {
			handleSearchButtonClick();
		}
	}, [location]);

	const clearSidebarInputs = (e: React.MouseEvent<HTMLButtonElement>) => {
		setStartDate("");
		setToDate("");
		setContain("");
		setWhere("");
		setCheckedLabels([]);
		setCheckedCharacters(new Map());
	}

	const [showLabelsModal, setShowLabelsModal] = useState<boolean>(false);
	const [showCharactersModal, setShowCharactersModal] = useState<boolean>(false);

	return (
		<>
			<nav id={`${styles.sidebar}`} className={props.isSidebarHidden ? "" : styles.active}>
				<div className="row justify-content-left text-start">
					<div className="col-12">
						<form method="POST" encType="multipart/form-data"
						      onSubmit={(e) => e.preventDefault()}>
							<div className="form-group mb-3">
								<label htmlFor="between">Between</label>
								<input className={`form-control ${styles.sidebarInput}`} id="fromDate" name="fromDate" type="date"
								       value={startDate}
								       onChange={(e) => setStartDate(e.target.value)}/>
								<input className={`form-control ${styles.sidebarInput}`} id="toDate" name="toDate" type="date"
								       value={toDate}
								       onChange={(e) => setToDate(e.target.value)}/>
							</div>

							<div className="form-group mb-3">
								<label htmlFor="containing">Containing</label>
								<input className={`form-control ${styles.sidebarInput}`} id="containing" name="containing"
								       placeholder="Title or story text" type="text"
								       value={contain}
								       onChange={(e) => setContain(e.target.value)}/>
							</div>

							<div className="form-group mb-3" id={styles.featuringSection}>
								<label htmlFor="">Featuring</label>
								<div className="d-flex">
									<AddButton onClick={() => setShowCharactersModal(true)} />
									{Array.from(checkedCharacters).map(([characterId, character], i) => {
										return (
											<img key={`sidebar-character-${characterId}`} src={character.ProfileThumbnail}
											     className={styles.sidebarThumbnail} alt="character's thumbnail"
											     style={{left: `${70 + ((170 / checkedCharacters.size) * i)}px`, zIndex: i}}
											     onError={(e) => (e.target as HTMLImageElement).src = defaultCharacterImage}/>
										);
									})}
								</div>
							</div>

							<div className="form-group mb-3">
								<label htmlFor="Where">Where</label>
								<input className={`form-control ${styles.sidebarInput}`} id="where" name="where" placeholder="Town or country" type="text"
								       value={where}
								       onChange={(e) => setWhere(e.target.value)}/>
							</div>

							<div className="form-group mb-3">
								<label htmlFor="">Labels</label>
								<div className="d-flex flex-wrap">
									<AddButton onClick={() => setShowLabelsModal(true)} />
									{checkedLabels.map((label, i) => {
										return (
											<div className={styles.labelItem} key={i.toString()}><p>{label.Title}</p></div>
										)
									})}
								</div>
							</div>
							<div className="form-group mb-3 text-center">
								<button type="submit" className="btn btn-storychest w-120px"
								        onClick={() => handleSearchButtonClick()}
								>Search</button>
							</div>

							<div className="form-group mb-3 text-center">
								<button type="button" id="clear-filter" className="btn btn-dark w-120px"
								        onClick={clearSidebarInputs}
								>Clear</button>
							</div>
						</form>
					</div>
				</div>
			</nav>

			<LabelsModal show={showLabelsModal} setShow={setShowLabelsModal} labels={labels} setLabels={setLabels}
			             checkedLabels={checkedLabels} setCheckedLabels={setCheckedLabels}
			/>
			<CharactersModal show={showCharactersModal} setShow={setShowCharactersModal}
											 characters={characters} setCharacters={setCharacters} pickCharacters={true}
			                 forceUpdate={forceUpdate} checkedCharacters={checkedCharacters} modalTitle="Your characters"
			/>
		</>
	);
}