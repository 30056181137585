import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector, useUserCharacters,} from "../../app/hooks";
import {RootState} from "../../app/store";
import styles from "styles/characters/characters.module.scss";
import defaultCharacterImage from "../../static/default_character_image.jpg";
import {CharacterTreeType, GroupType} from "../../adapters/types";
import {AddCharacterModal} from "./components/AddCharacterModal";
import {CharacterDetailsModal} from "./components/CharacterDetails";
import {setSelectedCharacterId} from "./context/characterSlice";
import createSnackbar, {SnackTypes} from "../../components/snackbar/Snackbar";
import {apiAddNewGroup} from "adapters/characters";
import {addNewGroupToUserData} from "../authorization/context/authSlice";
import {InputButtonGroup} from "../../components/forms/InputButtonGroup";
import {useMutation} from "react-query";
import {AxiosError} from "axios";

export const Characters = () => {
	const userData = useAppSelector((state: RootState) => state.auth.userData);
	const characters: CharacterTreeType[] = useUserCharacters();

	const groups: GroupType[] = userData!.groups;

	/** Currently shown will be the opposite. */
	const [selectedView, setSelectedView] = useState<"Groups" | "A-Z">("Groups");
	const switchSelectedView = () => setSelectedView(selectedView === "Groups" ? "A-Z" : "Groups");

	const [showAddCharacterModal, setShowAddCharacterModal] = useState<boolean>(false);

	return (<>
		<div className={`container-fluid content-wrapper content-wrapper-top-double-spacing text-center`}>
			<div className="row mb-3">
				<header className="justify-content-center text-center wrapper-icon">
					<span className="icon-users headline-icon"/>
					<h1>Characters</h1>
				</header>
			</div>

			<div className={`${styles.charactersContainer} container`}>
				<div className="row justify-content-center text-center">
					<div className="col-6 mt-3">
						<button type="button" className="btn btn-storychest btn-icon"
						        onClick={() => setShowAddCharacterModal(true)}
						>
							Add character
							<span className="icon-plus_in_circle text-white"/>
						</button>
					</div>
					<div className="col-6 mt-3">
						<button className={`btn btn-storychest`} type="button" onClick={() => switchSelectedView()}>
							{selectedView}
						</button>
					</div>
				</div>

				<div className={`mt-4`}>
					{selectedView === "Groups"
						? <AZView characters={characters}/>
						: <GroupsView characters={characters} groups={groups}/>
					}
				</div>

			</div>
		</div>

		<AddCharacterModal show={showAddCharacterModal} setShow={setShowAddCharacterModal} />
	</>)
}

interface GroupsViewProps {
	characters: CharacterTreeType[],
	groups: GroupType[]
}

const GroupsView = ({characters, groups}: GroupsViewProps) => {

	const dispatch = useAppDispatch();
	const [charactersByGroup, setCharactersByGroup] = useState<Map<number, CharacterTreeType[]>>(new Map());
	const [newGroup, setNewGroup] = useState<string>("");

	useEffect(() => {
		const _charactersByGroup: Map<number, CharacterTreeType[]> = new Map();
		groups.forEach(group => _charactersByGroup.set(group.Id, []));
		characters
			.forEach(character => character.GroupsIds
				.forEach(groupId => _charactersByGroup.get(groupId)?.push(character)));
		setCharactersByGroup(_charactersByGroup);
	}, [characters, groups]);

	const mutationAddNewGroup = useMutation(apiAddNewGroup, {
		onSuccess: (response) => {
			dispatch(addNewGroupToUserData(response.data.Content));
			setNewGroup("");
			createSnackbar("Group added!", SnackTypes.success);
		},
		onError: (error: AxiosError) => {
			createSnackbar(error.response ? error.response.data.Message : "Something went wrong.", SnackTypes.error);
		}
	});

	const addNewGroup = () => {
		if (newGroup.length === 0) {
			createSnackbar("Group can not be empty.", SnackTypes.warning);
			return;
		}
		mutationAddNewGroup.mutate({newGroupTitle: newGroup});
	}

	return (
		<div className="mt-5">
			<InputButtonGroup placeholder="Add new group"
			                  className={"justify-content-center"}
			                  inputClassName={"form-control flex-grow-0"}
			                  handleChange={setNewGroup}
			                  handleClick={addNewGroup}
			                  value={newGroup}
			                  inputStyle={{flexBasis: "300px"}}
			/>
			{Array.from(charactersByGroup).map(([groupId, charactersInGroup]) => { return (
				<section key={`characters-in-group-${groupId}`} className={`mt-5`}>
					<header className={`${styles.charactersSectionHeadline}`}>
						<h2>{groups.find(group => group.Id === groupId)?.Title}</h2>
						<hr className="mx-auto" />
					</header>
					<CharactersItems characters={charactersInGroup} />
				</section>
			)})}
		</div>
	)
}

interface AZViewProps {
	characters: CharacterTreeType[]
}

const AZView = ({characters}: AZViewProps) => {
	return (
		<CharactersItems characters={characters} />
	)
}

interface CharactersItemsProps {
	characters: CharacterTreeType[]
}

/** Character items/pins view. */
const CharactersItems = ({characters}: CharactersItemsProps) => {

	const [showCharacterDetailsModal, setShowCharacterDetailsModal] = useState<boolean>(false);

	return (<>
		<div className="row justify-content-center">
			<div className="col-12 mt-5 d-flex justify-content-center flex-wrap">
				{characters.map((character, i) => { return (
					<CharacterFigure key={`character-${i}`}
					                 characterId={character.Id}
					                 src={character.ProfileThumbnail}
					                 description={`${character.FirstName} ${character.LastName}`}
					                 isStorychestUser={character.IsStorychestUser}
					                 onClick={() => setShowCharacterDetailsModal(true)}
					/>
				)})}
			</div>
		</div>
		<CharacterDetailsModal show={showCharacterDetailsModal} setShow={setShowCharacterDetailsModal} />
	</>)
}

interface CharacterFigureProps {
	characterId: number,
	src?: string,
	description: string,
	isStorychestUser: boolean,
	onClick: () => void
}

const CharacterFigure = ({characterId, src, description, isStorychestUser, onClick}: CharacterFigureProps) => {

	const dispatch = useAppDispatch();

	const handleCharacterItemClick = () => {
		dispatch(setSelectedCharacterId(characterId));
		onClick();
	}

	return (
		<figure
			className={`${styles.characterFigure} d-flex flex-column align-items-center cursor-pointer`}
			onClick={handleCharacterItemClick}
		>
			<div className={`${styles.characterImage} pos-relative bg-image rounded-circle image-border-light`}
			     style={{backgroundImage: `url("${src}"), url(${defaultCharacterImage})`}}
			>
				{isStorychestUser &&
	        <div className={`${styles.storychestUserLogo}`}
	             title="Storychest user"
	        >
		        <span className={`icon-StorychestLogo text-white`} />
	        </div>
				}
			</div>
			<figcaption className={`mt-2`}>
				<span className={`storychest-text font-breelight`} style={{fontSize: "1.3em"}}>{description}</span>
			</figcaption>
		</figure>
	)
}