import React from "react";
import {Link} from "react-router-dom";

export const NotFound = () => {
  return (
    <div className="container content-wrapper mb-5 top-row">
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <h1>Not Found (404)</h1>
          <p className="text-black mt-3">Sorry, the page your are looking for does not exist.</p>
          <p>
            <Link to="/stories" className="btn btn-storychest">Take me home</Link>
          </p>
        </div>
      </div>
    </div>
  );
};