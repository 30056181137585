import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {apiGetStoriesByYearMonth, apiGetStoriesYearsMonths} from "../../adapters/stories";
import {RootState} from "../../app/store";
import {
  setStoriesYearsMonths,
  setStories,
  clearStories
} from "./context/storiesSlice";
import {useParams} from "react-router-dom";
import {getMonthName, scrollToTop} from "../../helpers/global";
import {StoriesList} from "./StoriesList";
import {startLoading, stopLoading, changeSubNavigationHref, changeSubNavigationText, changeText} from "pages/stories/context/storiesSlice";
import {useMutation} from "react-query";

export const StoriesByYearMonth = () => {
  const dispatch = useAppDispatch();

  const { year, month } = useParams<{year: string, month: string}>();

  const storiesYearsMonths = useAppSelector((state: RootState) => state.stories.storiesYearsMonths);

  useEffect(() => {
    dispatch(changeText(`Stories - ${getMonthName(month)}, ${year}`));
    dispatch(changeSubNavigationText("Go back"));
    dispatch(changeSubNavigationHref(`/stories/${year}/months`));
  }, [dispatch, month, year]);

  const mutation = useMutation(apiGetStoriesYearsMonths, {
    onSuccess: (response) => {
      dispatch(setStoriesYearsMonths(response.data.Content));
      dispatch(stopLoading());
    },
    onSettled: () => { dispatch(stopLoading()); }
  });

  useEffect(() => {
    if (storiesYearsMonths.Years.length === 0) {
      dispatch(startLoading());
      mutation.mutate();
    }
  }, [storiesYearsMonths.Years.length]);

  const mutationStoriesByYearMonth = useMutation(apiGetStoriesByYearMonth, {
    onSuccess: (response) => {
      dispatch(setStories(response.data.Content));
    },
    onSettled: () => { dispatch(stopLoading()); }
  });

  useEffect(() => {
    scrollToTop();
    dispatch(startLoading());

    let yearId = 0;
    let monthId = 0;
    for (const yearsMonths of storiesYearsMonths.Years) {
      if (yearsMonths.Title === year) {
        yearId = yearsMonths.Id;
        for (const months of yearsMonths.Months) {
          if (months.Title === month) {
            monthId = months.Id;
            break;
          }
        }
        break;
      }
    }

    mutationStoriesByYearMonth.mutate({yearId: yearId, monthId: monthId});

    return () => {  // on component unmount
      dispatch(clearStories());
    }
  }, [dispatch, month, storiesYearsMonths, year]);

  return (
    <StoriesList haveAtLeastOneStory={true}/>
  );
};