import {map} from "helpers/global";
import React from "react";
import styles from "styles/dropdown.module.scss";
import CSS from "csstype";
import {Dropdown} from "react-bootstrap";

interface DropdownScrollOnlyButtonProps {
	menuId: string,
	selectedValue: any
}

interface DropdownScrollOnlyMenuProps {
	menuId: string,
	setSelectedValue?: Function,
	dropdownMenuTitle?: string,
	dropdownMenuData?: Function,
	css?: CSS.Properties,
	className?: string,
	children?: React.ReactNode
}

export const DropdownScrollMenu = ({menuId, dropdownMenuTitle, selectedValue, setSelectedValue, dropdownMenuData,
                                    css, className, children}: DropdownScrollOnlyButtonProps & DropdownScrollOnlyMenuProps) => {
	return (
		<Dropdown>
			<DropdownScrollOnlyButton menuId={menuId} selectedValue={selectedValue}/>
			<DropdownScrollOnlyMenu menuId={menuId} dropdownMenuData={dropdownMenuData} css={css} className={className}
			                        children={children} dropdownMenuTitle={dropdownMenuTitle} setSelectedValue={setSelectedValue}/>
		</Dropdown>
	)
}

export const DropdownScrollOnlyButton = ({menuId, selectedValue}: DropdownScrollOnlyButtonProps) => {
	return (
		<Dropdown.Toggle as="div">
			<button type="button" className="btn btn-storychest">
				{selectedValue}
				<div className="rotated-90-icon-wrapper ms-2">
					<span className="icon-play text-white rotated-90-icon"/>
				</div>
			</button>
		</Dropdown.Toggle>
	)
}

export const DropdownScrollOnlyMenu = ({menuId, dropdownMenuTitle, setSelectedValue, dropdownMenuData,
                                        css, className, children}: DropdownScrollOnlyMenuProps) => {
	return (
		<Dropdown.Menu style={css} className={`${styles.dropdownMenuSelect} ${className}`}>
			{dropdownMenuTitle !== undefined &&
				<header>
				<h2>{dropdownMenuTitle}</h2>
				</header>
			}
			{(children !== undefined && children) ||
		   (dropdownMenuData !== undefined && map(dropdownMenuData(), (value: any, i: number) => {
				return (
					<Dropdown.Item key={`select-${menuId}-${i}`} className="cursor-pointer" onClick={() => {
						if (setSelectedValue !== undefined)
							setSelectedValue(value);
					}}>
						{value}
					</Dropdown.Item>
				)
			}))}
		</Dropdown.Menu>
	)
}