const ENV = process.env.NODE_ENV.toLowerCase() as "production" | "development";

const config = {
  API_URL: {
    development: "http://localhost:10016/api",
    production: "https://beta.storychest.com/api"  // "http://151.236.220.178:10017/api"
  }[ENV],
  EMAIL_CONTACT: " info@storychest.com"
}

export default config;