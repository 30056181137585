import React from "react";

interface StoryLabelProps {
	label: string,
	className?: string
}

export const StoryLabel = ({label, className}: StoryLabelProps) => {
	return (
		<div className={`wrapper-icon ${className === undefined ? "" : className}`}>
			<span className="icon-tag me-1 storychest-color"/>
			<span>{label}</span>
		</div>
	)
}