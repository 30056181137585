import {useEffect} from "react";
import {apiGetStories} from "../../adapters/stories";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {setStories, changeSubNavigationHref, changeSubNavigationText, changeText} from "pages/stories/context/storiesSlice";
import {startLoading, stopLoading} from "pages/stories/context/storiesSlice";
import {StoriesList} from "./StoriesList";
import {AppDispatch, RootState} from "../../app/store";
import {useLocation} from "react-router-dom";
import {scrollToTop} from "../../helpers/global";
import {useQuery} from "react-query";
import {Location} from "history";

export const Stories = () => {
  const dispatch = useAppDispatch();
  const location = useLocation<any>();

  const stories = useAppSelector((state: RootState) => state.stories.stories);

  useEffect(() => {
    if (location.search === "")  // update header title only if there is sign of filtering
      dispatch(changeText("Stories"));
    dispatch(changeSubNavigationText("list by years"))
    dispatch(changeSubNavigationHref("/stories/list-by-years"))
  }, [dispatch, location]);

  useStories({dispatch, location});

  useEffect(() => {
    // fetch only if there is no sign of redirect in state with parameters and stories are empty
    if (stories.length === 0 && location.search === "") {
      dispatch(startLoading());
    }
  }, []);

  return <StoriesList/>
}

/**
 *  Get all user stories if there are no (GET) query parameters.
 */
const useStories = (props: {dispatch: AppDispatch, location: Location}) => {
  useQuery("getStories", apiGetStories, {
    enabled: props.location.search === "",
    onSuccess: (response) => {
      props.dispatch(setStories(response.data.Content));
      scrollToTop();
    },
    onError: (error) => { console.error(error); },
    onSettled: () => { props.dispatch(stopLoading()); }
  });
}