import React, {useEffect, useState} from "react";
import styles from "styles/base_modal.module.scss";
import {CharacterTreeType} from "adapters/types";
import {apiDeleteCharacter, apiDeleteStorychestUserCharacter, apiGetCharacterDetails} from "adapters/characters";
import {useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {RootState} from "app/store";
import {HeartsLoader} from "components/HeartsLoader";
import {ProfileImage} from "components/forms/profile_image/ProfileImage";
import {CreateEditCharacterForm} from "./CreateEditCharacterForm";
import {YesNoModal} from "components/modals/YesNoModal";
import {CharacterTree} from "./CharacterTree";
import createSnackbar, {SnackTypes} from "components/snackbar/Snackbar";
import {deleteCharacterFromUserData} from "pages/authorization/context/authSlice";
import {EditCharacterGroups} from "./EditCharacterGroups";
import {ModalShowProps, SetShowModal} from "app/types";
import {Modal} from "react-bootstrap";
import {useMutation, useQuery} from "react-query";
import {AxiosError} from "axios";

enum ModalBodyPages {
	CHARACTER_DETAILS,
	EDIT_CHARACTER,
	CHARACTER_TREE,
	EDIT_GROUPS
}

export const CharacterDetailsModal = ({show, setShow}: ModalShowProps) => {

	const characterId: number | undefined = useAppSelector((state: RootState) => state.characters.selectedCharacterId);
	const [characterDetails, setCharacterDetails] = useState<CharacterTreeType | undefined>();

	const DEFAULT_MODAL_TITLE = "Character Details";

	const [page, setPage] = useState<ModalBodyPages>(ModalBodyPages.CHARACTER_DETAILS);
	const [modalTitle, setModalTitle] = useState<string>(DEFAULT_MODAL_TITLE);

	useQuery(["getCharacterDetails", characterId], () => apiGetCharacterDetails({characterId: characterId!}), {
		onSuccess: (response) => setCharacterDetails(response.data.Content),
		onError: (error: AxiosError) => createSnackbar(error.response?.data.Message, SnackTypes.error),
		enabled: characterId !== undefined
	})

	useEffect(() => {
		setCharacterDetails(undefined);
		setPage(ModalBodyPages.CHARACTER_DETAILS);
		setModalTitle(DEFAULT_MODAL_TITLE);
	}, [characterId]);

	const handleClose = () => setShow(false);

	return (
		<Modal
			show={show}
			onHide={handleClose}
			centered
			className={`${styles.charactersModal} ${styles.baseModal}`}
		>
			<Modal.Header className={`modal-header ${styles.modalHeader}`} closeButton>
				<Modal.Title>
					<div className="wrapper-icon">
						{page !== ModalBodyPages.CHARACTER_DETAILS &&
            <button
              className={`btn btn-link p-0 pe-3`}
              onClick={() => {
								setPage(ModalBodyPages.CHARACTER_DETAILS);
								setModalTitle(DEFAULT_MODAL_TITLE);
							}}
            >
              <span className={`icon-left_arrow`} />
            </button>
						}
						<h5>{modalTitle}</h5>
						<span className="icon-users storychest-color ms-1"/>
					</div>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className={`${styles.modalBody} text-center`}>
				{characterDetails
					?
					<CharacterDetailsBody
						page={page}
						setPage={setPage}
						setModalTitle={setModalTitle}
						character={characterDetails}
						setShow={setShow}
					/>
					:
					<HeartsLoader width={90} height={90} />
				}
			</Modal.Body>
		</Modal>
	)
}

interface CharacterDetailsBodyProps {
	page: ModalBodyPages,
	setPage: React.Dispatch<React.SetStateAction<ModalBodyPages>>,
	setModalTitle: React.Dispatch<React.SetStateAction<string>>,
	character: CharacterTreeType,
	setShow: SetShowModal
}

const CharacterDetailsBody = ({page, setPage, setModalTitle, character, setShow}: CharacterDetailsBodyProps) => {

	const history = useHistory();
	const dispatch = useAppDispatch();

	const CHARACTER_TREE_MODAL_TITLE = "Character's Tree"

	const [showDeleteCharacterModal, setShowDeleteCharacterModal] = useState<boolean>(false);
	const [showDeleteStorychestUserCharacterModal, setShowDeleteStorychestUserCharacterModal] = useState<boolean>(false);

	const showCharacterStories = () => {
		setShow(false);
		history.push(`/character/${character.Id}/stories`);
	}

	const showStoriesFeaturingCharacter = () => {
		setShow(false);
		history.push({
			pathname: "/stories",
			search: `?characterId=${character.Id}`
		})
	}

	const deleteCharacterMutationConfig = {
		onSuccess: () => {
			dispatch(deleteCharacterFromUserData(character.Id));
			setShow(false);
			createSnackbar("Character removed.", SnackTypes.success);
		},
		onError: (error: AxiosError) => {
			createSnackbar(error.response?.data.Message, SnackTypes.error);
		}
	}

	const deleteCharacterMutation = useMutation(apiDeleteCharacter, deleteCharacterMutationConfig);
	const deleteStorychestUserMutation = useMutation(apiDeleteStorychestUserCharacter, deleteCharacterMutationConfig);

	const deleteNonStorychestCharacter = () => deleteCharacterMutation.mutate({characterId: character.Id});
	const deleteStorychestUserCharacter = () => deleteStorychestUserMutation.mutate({characterId: character.Id});

	if (page === ModalBodyPages.CHARACTER_DETAILS) {
		return (<>
			<div className="row">
				<div className={`col-12 d-flex justify-content-center`}>
					<figure>
						<ProfileImage imageURL={character.ProfileThumbnail}/>
						<figcaption>
							<p className={`m-1`}>{character.FirstName} {character.LastName}</p>
							<p className={`wrapper-icon justify-content-center`}>
								<span className={`icon-calendar me-1`}/>
								{character.CustomDate.DateTxt}
							</p>
						</figcaption>
					</figure>
				</div>
			</div>
			<div className="row">
				<div className={`col-12 d-flex justify-content-center`}>
					{character.IsStorychestUser
						? <button type="button" className={`btn btn-storychest`}
						          onClick={showCharacterStories}
						>
							View {character.FirstName}'s Stories
						</button>
						: character.Email !== ""
							? <button type="button" className={`btn btn-storychest`} disabled>Invite to Storychest</button> // TODO
							: ""
					}
				</div>
			</div>
			<div className="row mt-4 justify-content-center">
				<div className={`col-12 mb-3`}>
					{character.IsStorychestUser
						? <button type="button" className={`btn btn-outline-danger`}
						          onClick={() => setShowDeleteStorychestUserCharacterModal(true)}
						>
							Remove from my character tree
						</button>
						: <button type="button" className={`btn btn-outline-danger`}
						          onClick={() => setShowDeleteCharacterModal(true)}
						>
							Delete character
						</button>
					}
					{character.IsStorychestUser
						? <button type="button" className={`btn btn-outline-dark m-2`}
						          onClick={() => {
						            setPage(ModalBodyPages.CHARACTER_TREE);
							          setModalTitle(CHARACTER_TREE_MODAL_TITLE);
						          }}
						>
							Character tree
						</button>
						: <button type="button" className={`btn btn-secondary m-2`}
						          onClick={() => {
							          setPage(ModalBodyPages.EDIT_CHARACTER);
							          setModalTitle("Edit Character");
						          }}>
							Edit details
						</button>
					}
					<button type="button" className={`btn btn-outline-dark`}
									onClick={() => {
										setPage(ModalBodyPages.EDIT_GROUPS);
										setModalTitle("Edit Groups");
									}}
					>
						Edit groups
					</button>
				</div>
			</div>
			<div className="row">
				<div className={`col-12`}>
					<button className="btn btn-storychest" type="button" onClick={() => showStoriesFeaturingCharacter()}>
						Stories featuring {character.FirstName}
					</button>
				</div>
			</div>
			<YesNoModal show={showDeleteCharacterModal}
			            setShow={setShowDeleteCharacterModal}
			            text="Do you really wish to delete this character permanently?"
			            handleYes={deleteNonStorychestCharacter}
			/>
			<YesNoModal show={showDeleteStorychestUserCharacterModal}
			            setShow={setShowDeleteStorychestUserCharacterModal}
			            text="Do you really wish to delete this character permanently?"
			            handleYes={deleteStorychestUserCharacter}
			/>
		</>)
	}

	if (page === ModalBodyPages.EDIT_CHARACTER)
		return <CreateEditCharacterForm character={character} />

	if (page === ModalBodyPages.CHARACTER_TREE)
		return <CharacterTree character={character}/>

	if (page === ModalBodyPages.EDIT_GROUPS)
		return <EditCharacterGroups character={character} />

	return <></>
}