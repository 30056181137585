import React, {useEffect, useState} from "react";
import {useParams, Link} from "react-router-dom";
import {DateItem} from "components/DateItem";
import {StoryLabel} from "./components/StoryLabel";
import {StoryCharacter} from "./components/StoryCharacter";
import {HeartsLoader} from "components/HeartsLoader";
import {useStoryDetails, UseStoryDetailsReturnType} from "app/hooks";
import {StoryImageGallery} from "./components/StoryImageGallery";

export const StoryDetails = () => {
	const { storyId } = useParams<{storyId: string}>();

	const {storyDetails, setStoryDetails, images, setImages, errorMessage, setErrorMessage, isFetched}: UseStoryDetailsReturnType 
		= useStoryDetails(parseInt(storyId));

	useEffect(() => {
		if (!isFetched) {
			setStoryDetails(undefined);
			setImages([]);
			setErrorMessage("");
		}
	}, [isFetched, setErrorMessage, setImages, setStoryDetails]);

	return (
		<div className="container-fluid content-wrapper">
			<div className="row mt-2">
				<div className="col d-flex justify-content-between">
					<Link to="/stories" className="wrapper-icon">
						<span className="icon-left_arrow me-2"/>
						<span>Go Back to Stories</span>
					</Link>
					{ storyDetails !== undefined && storyDetails.Author === undefined &&
							<Link to={`/story/${storyId}/edit`} className="wrapper-icon">
								<span>Edit Story</span>
								<span className="icon-right_arrow ms-2"/>
							</Link>
				}
				</div>
			</div>
			<div className="row justify-content-center">
				<div className="container mt-3 mb-5">
					<div className="row justify-content-center">
						<header>
							<h1 className="text-center">{storyDetails?.Headline}</h1>
						</header>
					</div>
					<div className="row mt-3">
						<div className="d-flex flex-wrap justify-content-center">
							{storyDetails !== undefined && <DateItem dateTxt={storyDetails.CustomDate.DateTxt}/> }
							{storyDetails !== undefined && storyDetails.Labels.map((label) => {
								return (
									<StoryLabel key={`label-preview-story-${label.Id}`} label={label.Title} className="ms-2"/>
								)
							})}
						</div>
					</div>
					<div className="row">
						{storyDetails !== undefined && storyDetails.Location !== "" &&
							<div className="mt-2 wrapper-icon justify-content-center">
								<span className="icon-navigation me-1"/>
								<span>{storyDetails?.Location}</span>
							</div>
						}
					</div>
					<div className="row justify-content-center">
						<div className="d-flex align-items-center justify-content-center flex-wrap mt-1">
							{storyDetails !== undefined && storyDetails.CharactersTagged.map((character) => {
								return (
									<StoryCharacter key={`character-preview-story-${character.Id}`} character={character} className="me-2 mt-1"/>
								)
							})}
						</div>
					</div>
					<div className="row mt-4 justify-content-center">
						{!isFetched
							? <div className="col-12 d-flex justify-content-center"><HeartsLoader/></div>
							: errorMessage.length !== 0
								? <div className="text-center">{errorMessage}</div>
								: <>
										{ storyDetails?.MediaFiles.length !== 0 &&
												<div className={storyDetails?.Description.length === 0 ? "col-12" : "col-md-7 col-12"}>
														<StoryImageGallery images={images} />
												</div>
										}
										{ storyDetails?.Description.length !== 0 &&
												<div className={`text-justify ${storyDetails?.MediaFiles.length === 0 ? "col-8" : "col-md-5 col-12"}`}>
													{storyDetails?.Description}
												</div>
										}
									</>
						}
					</div>
				</div>
			</div>
		</div>
	);
}