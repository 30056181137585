import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import React, {Dispatch} from "react";
import {DropzoneFileProp} from "./Dropzone";
import {isVideoType} from "helpers/validation";
import defaultVideoImgPlaceholder from "static/default_video_placeholder.png";
import {FilesAction, FilesActionKind} from "../CreateEditStoryForm";

type Props = {
	files: DropzoneFileProp[],
	dispatchFiles: Dispatch<FilesAction>,
	filesToDelete: number[],
	setFilesToDelete: Function
};

/**
 * @param files State from parent's component.
 * @param dispatchFiles Update state in parent's component.
 * @param filesToDelete (edit story) Files that are already uploaded and saved and user is deleting them.
 * @param setFilesToDelete Update state in parent's component.
 * @constructor
 */
export const DragAndDrop = ({files, dispatchFiles, filesToDelete, setFilesToDelete}: Props) => {
	// function to help with reordering the result after drag&drop
	const reorder = (startIndex: number, endIndex: number) => {
		const _files = [...files];
		const [removed] = _files.splice(startIndex, 1);
		_files.splice(endIndex, 0, removed);
		dispatchFiles({type: FilesActionKind.SET_FILES, payload: _files});
	};

	const remove = (e: React.MouseEvent<HTMLSpanElement>, file: DropzoneFileProp,
	                filesToDelete: number[], setFilesToDelete: Function) => {
		let _files = [...files];
		_files.splice(_files.findIndex((_file) => _file.id === file.id), 1);
		dispatchFiles({type: FilesActionKind.SET_FILES, payload: _files});
		if (file.mediaId !== undefined) {
			const _filesToDelete = [...filesToDelete]
			_filesToDelete.push(file.mediaId);
			setFilesToDelete(_filesToDelete);
		}
	};

	return (
		<DragDropContext onDragEnd={(result) => {
			if (!result.destination)
				return;  // dropped outside the list
			reorder(result.source.index, result.destination.index);
		}}>
			<Droppable droppableId="droppable" direction="horizontal">
				{(provided, snapshot) => (
					<div ref={provided.innerRef} {...provided.droppableProps} className="items storychest-box-shadow-light"
					     style={{background: "white", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px"}}
					>
						{files.map((item, index) => (
							<Draggable key={item.id} draggableId={item.id} index={index} >
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
									>
										<div style={{position: "relative", margin: ".75rem"}}>
											<img src={isVideoType(item.htmlFile.type) ? defaultVideoImgPlaceholder : item.file} alt="uploaded file"
											     width={100} height={100}
										       className={"rounded-circle"}
										       style={{border: "1px solid black", objectFit: "cover"}}
											/>
											<span className="icon-cross cursor-pointer"
											      style={{position: "absolute", top: "1px", right: "1px", fontSize: ".8em"}}
											      onClick={(e) => remove(e, item, filesToDelete, setFilesToDelete)}/>
										</div>
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}