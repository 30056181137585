import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import storiesReducer from "pages/stories/context/storiesSlice";
import grapevineReducer from "pages/grapevine/context/grapevineSlice";
import navbarReducer from "components/navbar/context/navbarSlice";
import authReducer from "pages/authorization/context/authSlice";
import charactersReducer from "pages/characters/context/characterSlice";

export const store = configureStore({
  reducer: {
    navbar: navbarReducer,
    stories: storiesReducer,
    grapevine: grapevineReducer,
    characters: charactersReducer,
    auth: authReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
