import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {ApiGrapevineContentType} from "adapters/types";

const grapevinesPerPage = 5;

export interface GrapevineState {
  grapevines: ApiGrapevineContentType[],
  loadedGrapevines: number,
  grapevinesPerPage: number
}

const initialState: GrapevineState = {
  grapevines: [],
  loadedGrapevines: 0,
  grapevinesPerPage: grapevinesPerPage
};

interface SetGrapevinesAction {
  grapevines: ApiGrapevineContentType[],
  selectOnlyWithCharacterId?: number
}

export const grapevineSlice = createSlice({
  name: "grapevine",
  initialState,
  reducers: {
    setGrapevines: (state, action: PayloadAction<SetGrapevinesAction>) => {
      let grapevines = action.payload.grapevines;
      const selectOnlyWithCharacterId = action.payload.selectOnlyWithCharacterId;
      if (selectOnlyWithCharacterId !== undefined) {
        grapevines = grapevines.filter(g => g.Author.Id === selectOnlyWithCharacterId);
      }
      state.grapevines = grapevines;
      state.loadedGrapevines = grapevinesPerPage;
    },
    clearGrapevines: (state) => {
      state.grapevines = [];
      state.loadedGrapevines = 0;
    },
    setLoadedGrapevines: (state, action: PayloadAction<number>) => {
      state.loadedGrapevines = action.payload;
    },
    updateGrapevineByIndex: (state, action: PayloadAction<{index: number, grapevineRow: ApiGrapevineContentType}>) => {
      state.grapevines[action.payload.index] = action.payload.grapevineRow;
    }
  }
});

export const {
  setGrapevines,
  clearGrapevines,
  setLoadedGrapevines,
  updateGrapevineByIndex
} = grapevineSlice.actions;

export default grapevineSlice.reducer;
