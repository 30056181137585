import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {Route, Switch} from "react-router-dom";
import {Sidebar} from "./components/Sidebar";
import {Link} from "react-router-dom";
import storiesStyles from "styles/stories/stories.module.scss";
import {Stories} from "./Stories";
import {Years} from "./Years";
import {RootState} from "../../app/store";
import {toggleSidebarActive, hideSidebar} from "../../components/navbar/context/navbarSlice";
import {Months} from "./Months";
import {StoriesByYearMonth} from "./StoriesByYearMonth";
import {clearStories, clearStoriesYearsMonths} from "./context/storiesSlice";
import {HeartsLoader} from "../../components/HeartsLoader";

export const StoriesLayout = () => {
	const [isSidebarHidden, setSidebarHidden] = useState(true);

	const dispatch = useAppDispatch();
	const headerText = useAppSelector((state: RootState) => state.stories.text);
	const subNavigationText = useAppSelector((state: RootState) => state.stories.subNavigationText);
	const subNavigationHref = useAppSelector((state: RootState) => state.stories.subNavigationHref);
	const loading = useAppSelector((state: RootState) => state.stories.loading);

	useEffect(() => {
		return () => {
			dispatch(hideSidebar());
			dispatch(clearStories());
			dispatch(clearStoriesYearsMonths());
		}
	}, []);

	return (
		<>
			<Sidebar isSidebarHidden={isSidebarHidden}/>

			<div className={`container-fluid ${isSidebarHidden ? "" : storiesStyles.active}`} id={storiesStyles.storiesWrapper}>
				<div className={storiesStyles.sidebarCollapseButtonContainer}>
					<button type="button" className={`btn btn-link ${storiesStyles.sidebarCollapseButton}`}
					        onClick={(e) => {
						        setSidebarHidden(!isSidebarHidden);
						        dispatch(toggleSidebarActive());
					        }}>
						<span
							className={`${isSidebarHidden ? `icon-magnifier ${storiesStyles.iconMagnifier}` 
																						: `icon-cross ${storiesStyles.iconCross}`}`}
						/>
					</button>
				</div>

				<div className="row justify-content-center text-center top-row">
					<div className="col-12">
						<header className="text-center">
							<span className="icon-book headline-icon"/>
							<h1 className="d-inline-block" id="stories-headline">{headerText}</h1>
						</header>
					</div>
					<div className="col-6 mt-5">
						<Link to="/story/create" className="btn btn-storychest btn-icon">
							Create story
							<span className="icon-plus_in_circle text-white"/>
						</Link>
					</div>
					<div className="col-6 mt-5">
						<Link to={subNavigationHref} id="stories-navigation-link" className="text-with-icon-wrapper">
							<div className={`${storiesStyles.iconRightArrowWrapper}`}>
								<span className={`icon-right_arrow ${storiesStyles.iconRightArrow}`}/>
							</div>
							<span id="stories-navigation-text">{subNavigationText}</span>
						</Link>
					</div>

					<div id={storiesStyles.storiesContainer} className="col-12 mt-5 mb-5">
						<Switch>
							<Route exact path="/stories" component={Stories}/>
							<Route exact path="/stories/list-by-years" component={Years}/>
							<Route path="/stories/:year/months" component={Months}/>
							<Route path="/stories/:year/:month" component={StoriesByYearMonth}/>
						</Switch>
						{loading && <HeartsLoader/>}
					</div>
				</div>
			</div>
		</>
	);
};