import {Login} from "./Login";
import {Route, Switch} from "react-router-dom";
import {AuthRoute} from "../../App";
import {ForgotPassword} from "./ForgotPassword";
import loginBgCoverImage from "../../static/login_cover.jpeg";
import styles from "./styles/login_layout.module.scss";

export const LoginLayout = () => {
  return (
    <div className={`container-fluid content-wrapper ${styles.loginContentWrapper} content-wrapper-top-double-spacing`}
         style={{backgroundImage: `url(${loginBgCoverImage})`}}>
      <div className="row justify-content-center text-center">
        <div className="col-12 d-flex flex-column align-items-center">
          <div id={styles.loginBox} className="box-shadow-hard">
            <Switch>
              <AuthRoute exact path="/login" component={Login}/>
              <Route exact path="/forgot-password" component={ForgotPassword}/>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}