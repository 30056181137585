import React, {useState} from "react";
import styles from "styles/base_modal.module.scss";
import {apiAddCharactersToTree, apiGetCharactersByEmail} from "adapters/characters";
import {isEmailValid} from "../../../helpers/validation";
import {CreateEditCharacterForm} from "./CreateEditCharacterForm";
import {ProfileImage} from "components/forms/profile_image/ProfileImage";
import {CharacterTreeType} from "../../../adapters/types";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {RootState} from "../../../app/store";
import createSnackbar, {SnackTypes} from "../../../components/snackbar/Snackbar";
import {addOrEditCharacterToUserData} from "../../authorization/context/authSlice";
import {CharacterBrowse} from "./CharactersBrowse";
import {Modal} from "react-bootstrap";
import {ModalShowProps, SetShowModal} from "app/types";
import {InputButtonGroup} from "../../../components/forms/InputButtonGroup";
import {useMutation, useQuery} from "react-query";
import {AxiosError} from "axios";

enum ModalBodyPages {
	OPTIONS,
	CREATE_CHARACTER,
	BROWSE_CHARACTERS,
	SEARCH_BY_EMAIL
}

const DEFAULT_MODAL_TITLE = "Add character";

export const AddCharacterModal = ({show, setShow}: ModalShowProps) => {

	const [page, setPage] = useState<ModalBodyPages>(ModalBodyPages.OPTIONS);
	const [modalTitle, setModalTitle] = useState<string>(DEFAULT_MODAL_TITLE);

	const handleClose = () => setShow(false);

	return (
		<Modal
			show={show}
			centered
			onHide={handleClose}
			className={`${styles.charactersModal} ${styles.baseModal}`}
		>
			<Modal.Header className={`${styles.modalHeader}`} closeButton>
				<Modal.Title>
					<div className="wrapper-icon">
						{ page !== ModalBodyPages.OPTIONS &&
							<button
								className={`btn btn-link p-0 pe-3`}
								onClick={() => {
									setPage(ModalBodyPages.OPTIONS);
									setModalTitle(DEFAULT_MODAL_TITLE);
								}}
							>
								<span className={`icon-left_arrow`} />
              </button>
						}
						<h5>{modalTitle}</h5>
						<span className="icon-users storychest-color ms-1"/>
					</div>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className={`${styles.modalBody} text-center`}>
				<ModalBody page={page} setPage={setPage} setModalTitle={setModalTitle} setShow={setShow} />
			</Modal.Body>
		</Modal>
	)
}

interface ModalBodyProps {
	page: ModalBodyPages,
	setPage: React.Dispatch<React.SetStateAction<ModalBodyPages>>,
	setModalTitle: React.Dispatch<React.SetStateAction<string>>,
	setShow: SetShowModal
}

const ModalBody = ({page, setPage, setModalTitle, setShow}: ModalBodyProps) => {

	const dispatch = useAppDispatch();
	const userData = useAppSelector((state: RootState) => state.auth.userData);
	const [searchedEmail, setSearchedEmail] = useState<string>("");
	const [characterSearchedByEmail, setCharacterSearchedByEmail] = useState<CharacterTreeType | undefined>();

	const {refetch} = useQuery(["getCharactersByEmail", searchedEmail],
		() => apiGetCharactersByEmail({email: searchedEmail}),
		{
			onSuccess: (response) => {
				setPage(ModalBodyPages.SEARCH_BY_EMAIL);
				setModalTitle("Found character by email");
				setCharacterSearchedByEmail(response.data.Content);
			},
			onError: (error: AxiosError) => {
				if (error.response?.status === 204)
					createSnackbar("Character not found.", SnackTypes.error);
			},
			enabled: false,
			retry: false
		}
	);

	const handleFindByEmail = () => {
		if (!isEmailValid(searchedEmail)) {
			createSnackbar("Email is not valid.", SnackTypes.warning); return;
		}
		refetch();
	}

	const addCharacterToTreeMutation = useMutation(apiAddCharactersToTree, {
		onSuccess: () => {
			setShow(false);
			dispatch(addOrEditCharacterToUserData(characterSearchedByEmail!));
			createSnackbar("Character saved!", SnackTypes.success);
			setPage(ModalBodyPages.OPTIONS);
			setModalTitle(DEFAULT_MODAL_TITLE);
		},
		onError: (error: AxiosError) => {
			createSnackbar(error.response?.data.Message, SnackTypes.error);
		}
	});

	const addCharacterToTree = () => {
		if (characterSearchedByEmail === undefined) return;
		const characterIds: number[] = [characterSearchedByEmail.Id];
		addCharacterToTreeMutation.mutate({characterIds: characterIds});
	}

	if (page === ModalBodyPages.OPTIONS) {
		return (<>
			<InputButtonGroup placeholder="Search by email"
			                  className={"justify-content-center"}
			                  inputClassName={"form-control flex-grow-0"}
			                  handleChange={setSearchedEmail}
			                  handleEnterPress={handleFindByEmail}
			                  handleClick={handleFindByEmail}
			                  value={searchedEmail}
			                  inputStyle={{flexBasis: "300px"}}
			                  customIcon={<span className="icon-magnifier" style={{fontSize: "1.2em"}} />}
			/>
			<div className={`mt-3 mb-3`}>
				<button
					className={`btn btn-storychest`}
					onClick={() => {
						setPage(ModalBodyPages.BROWSE_CHARACTERS);
						setModalTitle("Browse Characters");
					}}
				>
					Browse Characters
				</button>
			</div>
			<div>
				<button
					className={`btn btn-storychest`}
					onClick={() => {
						setPage(ModalBodyPages.CREATE_CHARACTER);
						setModalTitle("Create New Character");
					}}
				>
					Create New Character
				</button>
			</div>
		</>)
	}

	if (page === ModalBodyPages.CREATE_CHARACTER )
		return <CreateEditCharacterForm setShow={setShow} />

	if (page === ModalBodyPages.BROWSE_CHARACTERS )
		return <CharacterBrowse />

	if (page === ModalBodyPages.SEARCH_BY_EMAIL )
		return (<>
			<div className="row">
				<div className={`col-12 d-flex justify-content-center`}>
					<figure>
						<ProfileImage imageURL={characterSearchedByEmail?.ProfileThumbnail} />
						<figcaption>
							<p className={`m-1`}>{ characterSearchedByEmail?.FirstName } { characterSearchedByEmail?.LastName }</p>
						</figcaption>
					</figure>
				</div>
			</div>
			<div className="row">
				<div className={`col-12 d-flex justify-content-center`}>
					{ characterSearchedByEmail?.Id === userData?.userId
						? <></>
						: characterSearchedByEmail?.IsInUserTree
							? <span>Character is already in your character tree.</span>
							: <button type="button" className={`btn btn-storychest`}
							          onClick={addCharacterToTree}
								>
									Add to your character tree
								</button>
					}
				</div>
			</div>
		</>)

	return <></>
}