import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {apiGetStoriesYearsMonths} from "adapters/stories";
import {RootState} from "app/store";
import {setStoriesYearsMonths} from "pages/stories/context/storiesSlice";
import {Link} from "react-router-dom";
import {startLoading, stopLoading, changeSubNavigationHref, changeSubNavigationText, changeText} from "pages/stories/context/storiesSlice";
import styles from "styles/stories/story_item.module.scss";
import {useMutation} from "react-query";

export const Years = () => {
  const dispatch = useAppDispatch();
  const storiesYearsMonths = useAppSelector((state: RootState) => state.stories.storiesYearsMonths);

  useEffect(() => {
    dispatch(changeText("Stories - List by years"));
    dispatch(changeSubNavigationText("Go back"));
    dispatch(changeSubNavigationHref("/stories"));
  }, [dispatch]);
  
  const mutation = useMutation(apiGetStoriesYearsMonths, {
    onSuccess: (response) => {
      dispatch(setStoriesYearsMonths(response.data.Content));
      dispatch(stopLoading());
    },
    onSettled: () => { dispatch(stopLoading()); }
  });

  useEffect(() => {
    if (storiesYearsMonths.Years.length === 0) {
      dispatch(startLoading());
      mutation.mutate();
    }
  }, [storiesYearsMonths.Years.length]);

  return (
    <>
      {storiesYearsMonths.Years.map((storyYearMonth, i) => { return (
        <Link to={`/stories/${storyYearMonth.Title}/months`} key={`storyYear-${storyYearMonth.Id}`}>
          <figure className="figure p-4">
            <div className={styles.coverCircleBgImageContainer}>
              <div className={`rounded-circle ${styles.coverCircleBgImage}`} style={{backgroundImage: `url("${storyYearMonth.Cover}")`}}/>
            </div>
            <figcaption className="figure-caption text-center m-2">
              <div className="">
                <div>
                  <h2 className="storychest-color">{storyYearMonth.Title}</h2>
                </div>
                <div>
                  <span>{storyYearMonth.Count} {storyYearMonth.Count === 1 ? "Story" : "Stories"}</span>
                </div>
              </div>
            </figcaption>
          </figure>
        </Link>
      )})}
    </>
  );
};