import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import type { RootState, AppDispatch } from './store';
import {useEffect, useReducer, useState, ReactNode, SetStateAction, Dispatch} from "react";
import {ApiResponse, ApiStoryDetailsContentType, CharacterTreeType} from "../adapters/types";
import {imageExists} from "../helpers/images";
import {apiGetStoryDetails} from "../adapters/stories";
import {ReactImageGalleryItem} from "react-image-gallery";
import {useQuery} from "react-query";
import defaultVideoImgPlaceholder from "static/default_video_placeholder.png";
import {AxiosError} from "axios";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useCharacterDetails = (characterId?: number): CharacterTreeType | undefined => {
	const userData = useAppSelector((state: RootState) => state.auth.userData);
	const [character, setCharacter] = useState<CharacterTreeType | undefined>();
	useEffect(() => {
		if (characterId === undefined)
			setCharacter(userData?.characters[userData?.characterIndex]);
		else
			setCharacter(userData?.characters.find(ch => ch.Id === characterId));
	}, [characterId, userData]);
	return character;
}

/** Get all characters except a character of logged in user. */
export const useUserCharacters = (): CharacterTreeType[] => {
	const userData = useAppSelector((state: RootState) => state.auth.userData);
	const [characters, setCharacters] = useState<CharacterTreeType[]>([]);
	useEffect(() => {
		if (userData === undefined) return;
		const characters = [...userData.characters];
		characters.splice(userData.characterIndex, 1);
		setCharacters(characters);
	}, [userData]);
	return characters;
}

export interface GalleryImageStateType {
	original: string,
	thumbnail: string,
	renderItem?: (item: ReactImageGalleryItem) => ReactNode,
	isVideo: boolean
}

export interface UseStoryDetailsReturnType {
	storyDetails: ApiStoryDetailsContentType | undefined,
	images: GalleryImageStateType[],
	setStoryDetails: Dispatch<SetStateAction<ApiStoryDetailsContentType | undefined>>,
	setImages: Dispatch<SetStateAction<GalleryImageStateType[]>>,
	errorMessage: string,
	setErrorMessage: Dispatch<SetStateAction<string>>,
	isFetched: boolean
}

export interface VideoErrorState {
	indexes: number[]
}

export enum VideoErrorActionKind {
	UPDATE_INDEXES,
	REMOVE_INDEX
}

export type VideoErrorAction =
{
	type: VideoErrorActionKind.UPDATE_INDEXES,
	payload: number[]
}
|
{
	type: VideoErrorActionKind.REMOVE_INDEX,
	payload: number
}

const VideoError = ({downloadLink}: {downloadLink: string}) => {
	return (
		<div className="w-100 h-100 bg-black p-5 text-center d-flex text-center align-items-center justify-content-center"
				 style={{whiteSpace: "normal", lineHeight: "1.5"}}
		>
			<span className="text-white">
				We are sorry. Your browser does not support this type of video.
				Please, try using a different browser or <a href={downloadLink}>download</a> the video.
			</span>
		</div>
	);
}

export const useStoryDetails = (storyId: number): UseStoryDetailsReturnType => {
	const [storyDetails, setStoryDetails] = useState<ApiStoryDetailsContentType>();
	const [images, setImages] = useState<GalleryImageStateType[]>([]);
	const [errorMessage, setErrorMessage] = useState<string>("");

	/**
	 * Reducer to store indexes to `images` local state.
	 * Each index i in `videoError.indexes` map to `images[i]` when `images[i]` is a video and a user browser is unable to play it.
	 * Reason why we need to use useReducer instead of useState is described in `useQuery` of this hook.
	 */
	const [videoError, dispatchVideoError] = useReducer(
		(state: VideoErrorState, action: VideoErrorAction) => {
			switch (action.type) {
				case VideoErrorActionKind.UPDATE_INDEXES:
					return {indexes: [...state.indexes, ...action.payload]};
				case VideoErrorActionKind.REMOVE_INDEX:
					const _indexes = [...state.indexes];
					const i = _indexes.indexOf(action.payload);
					if (i !== -1)
						_indexes.splice(i, 1);
					return {indexes: _indexes};
				default:
					throw new Error();
			}
		},
		{ indexes: [] }
	);

	/**
	 * Change renderItem (i.e. what is displayed in react-image-gallery for specific item) to error message for each i
	 * in `videoError.indexes` to `images[i].renderItem`.
	 */
	useEffect(() => {
		for (const i of videoError.indexes) {
			const _images = [...images];
			_images[i].renderItem = () => <VideoError downloadLink={_images[i].original} />
			setImages(_images);
			dispatchVideoError({type: VideoErrorActionKind.REMOVE_INDEX, payload: i});
		}
	}, [videoError, images, setImages]);

	/***
	 * Fetch story details when `storyId` prop changes. On success, set story details and prepare files for rendering for
	 * react-image-gallery. If a file is a video, we determine if a user browser is able to play it. If not, we use
	 * update a `videoError` local reducer. We need to use useReducer instead of useState because the information that a
	 * video can not be played takes various amount of time and hence updating a state of indexes in typical way
	 * [...state, newValue] would not work because the state would not be updated i.e. for multiple un-playable videos
	 * the state would only contain the last one resolved.
	 * Sources:
	 * https://react-query.tanstack.com/guides/dependent-queries
	 * https://stackoverflow.com/questions/68068854/how-detect-if-audio-only-when-playing-mp4-hevc-h265-video-files-in-incompatibl
	 */
	const {isFetched} = useQuery(["storyDetails", storyId], () => apiGetStoryDetails({storyId: storyId}), {
		enabled: storyId >= 0,
		onSuccess: async (response) => {
			setStoryDetails(response.data.Content);
			let _images: GalleryImageStateType[] = [];
			for (let i = 0; i < response.data.Content.MediaFiles.length; i++) {
				const mediaFile = response.data.Content.MediaFiles[i];
				const image: GalleryImageStateType = {original: "", thumbnail: "", renderItem: undefined, isVideo: false}
				image.original = mediaFile.Url;
				if (mediaFile.Type === 1) {
					image.thumbnail = defaultVideoImgPlaceholder;
					image.isVideo = true;
					image.renderItem = (item) => (
						<video controls
									 className="image-gallery-image w-100 h-100"
									 onLoadedMetadata={(e) => {
										 if((e.target as HTMLVideoElement).videoHeight === 0) {
											 dispatchVideoError({type: VideoErrorActionKind.UPDATE_INDEXES, payload: [i]});
										 }
									 }}
							>
								<source src={item.original} type="video/mp4"/>
								We are sorry. Your browser does not support this type of video. Please, try using a different browser or downloading the video in its settings.
							</video>
					);
				}
				else {
					try {
						const thumbExists = await imageExists(mediaFile.ThumbnailUrl);
						image.thumbnail = thumbExists ? mediaFile.ThumbnailUrl : mediaFile.MainUrl;
					} catch (errorMessage) {
						image.thumbnail = mediaFile.MainUrl;
					}
				}
				_images.push(image);
			}
			setImages(_images);
		},
		onError: (error: AxiosError<ApiResponse>) => {
			if (error.response?.status === 403)
				setErrorMessage(error.response.data.Message);
		}
	});

	return {storyDetails, setStoryDetails, images, setImages, errorMessage, setErrorMessage, isFetched}
}

export interface UsePickDateReturnType {
	selectedYear: number,
	setSelectedYear: (year?: number) => void,
	selectedMonth: number,
	setSelectedMonth: (month?: number) => void,
	selectedDay: number,
	setSelectedDay: Dispatch<SetStateAction<number>>,
	setSelectedDate: (date: Date) => void
}

export const usePickDate = (defaultYear?: number, defaultMonth?: number, defaultDay?: number): UsePickDateReturnType => {
	const [selectedYear, _setSelectedYear] = useState<number>(
		(defaultYear !== undefined) ? defaultYear : new Date().getFullYear()
	);
	/** 0-12, where 1 is January and 0 means undefined. */
	const [selectedMonth, _setSelectedMonth] = useState<number>(
		(defaultMonth !== undefined) ? defaultMonth : 0
	);
	/** 0-31, where 0 means undefined. */
	const [selectedDay, setSelectedDay] = useState<number>(
		(defaultDay !== undefined) ? defaultDay : 0
	);

	const setSelectedYear = (year?: number) => {
		_setSelectedYear(year || new Date().getFullYear());
		setSelectedDay(0);
	}

	const setSelectedMonth = (month?: number) => {
		_setSelectedMonth(month || 0);
		setSelectedDay(0);
	}

	/** Function that changes states for year, month and day from one `date` object.
	 *  Use only if `date` contains year, month and day.
	 *  Note: Date.getMonth() returns month between 0-11 (0 = January), but `selectedMonth` state expects value between
	 *        1-12 for correct month.
	 *  Note: Date.getDate() returns day in the month while Date.getDay() returns day in the week.
	 * */
	const setSelectedDate = (date: Date) => {
		setSelectedYear(date.getFullYear());
		setSelectedMonth(date.getMonth() + 1);
		setSelectedDay(date.getDate());
	}

	return {selectedYear, setSelectedYear, selectedMonth, setSelectedMonth, selectedDay, setSelectedDay, setSelectedDate};
}

export interface UsePickGroupsReturnType {
	sharedGroups: Map<number, boolean>,
	setSharedGroups: ((value: (((prevState: Map<number, boolean>) => Map<number, boolean>) | Map<number, boolean>)) => void)
}

type UsePickGroupsProps = Map<number, boolean>;

export const usePickGroups = (defaultSharedGroups?: UsePickGroupsProps): UsePickGroupsReturnType => {
	const userData = useAppSelector((state: RootState) => state.auth.userData);
	const [sharedGroups, setSharedGroups] = useState<Map<number, boolean>>(
		(defaultSharedGroups !== undefined) ? defaultSharedGroups : new Map(userData?.groups.map(group => [group.Id, false]))
	);

	return {sharedGroups, setSharedGroups};
}

