import { createSlice } from '@reduxjs/toolkit';

export interface NavbarState {
  sidebarActive: boolean
}

const initialState: NavbarState = {
  sidebarActive: false
};

export const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    toggleSidebarActive: (state) => {
      state.sidebarActive = !state.sidebarActive;
    },
    hideSidebar: (state) => {
      state.sidebarActive = false;
    }
  }
});

export const { toggleSidebarActive, hideSidebar } = navbarSlice.actions;

export default navbarSlice.reducer;
