import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {clearUserData, getUserData, isUserLoggedIn, setUserData, updateUserData} from "app/localStorage";
import {ApiLoginResponseContent, CharacterTreeType, CustomDate, GroupType} from "adapters/types";
import {UserDataType} from "app/types";

export interface AuthState {
  isUserLoggedIn: boolean,
  userData: UserDataType | undefined
}

const initialState: AuthState = {
  isUserLoggedIn: isUserLoggedIn(),
  userData: getUserData()
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser: (state, action: PayloadAction<ApiLoginResponseContent>) => {
      state.isUserLoggedIn = true;
      setUserData(action.payload);
      state.userData = getUserData();
    },
    logoutUser: (state) => {
      state.isUserLoggedIn = false;
      state.userData = undefined;
      clearUserData();
    },
    addOrEditCharacterToUserData: (state, action: PayloadAction<CharacterTreeType>) => {
      if (state.userData !== undefined) {
        const character: CharacterTreeType = action.payload;
        const userTreeCharacterIndex = state.userData.characters.findIndex(ch => ch.Id === character.Id);
        if (userTreeCharacterIndex === -1)
          state.userData.characters.push(action.payload);
        else
          state.userData.characters[userTreeCharacterIndex] = character;
        updateUserData(state.userData);
      }
    },
    editProfileInUserData: (state, action: PayloadAction<{userId: number, firstName: string, lastName: string,
                                                                     email?: string, profileImageURL?: string, birthday?: CustomDate}>
    ) => {
      if (state.userData === undefined) return;
      const character = state.userData.characters.find(ch => ch.Id === action.payload.userId)!;
      character.FirstName = action.payload.firstName;
      character.LastName = action.payload.lastName;
      character.Email = action.payload.email || character.Email;
      character.CustomDate = action.payload.birthday || character.CustomDate;
      character.ProfileImage = action.payload.profileImageURL || character.ProfileImage;
      character.ProfileThumbnail = action.payload.profileImageURL || character.ProfileThumbnail;
      updateUserData(state.userData);
    },
    addCharactersToUserData: (state, action: PayloadAction<CharacterTreeType[]>) => {
      if (state.userData !== undefined) {
        for (const character of action.payload) {
          const isInUserTree: boolean = state.userData.characters.find(ch => ch.Id === character.Id) !== undefined;
          if (!isInUserTree)
            state.userData.characters.push(character);
        }
        updateUserData(state.userData);
      }
    },
    deleteCharacterFromUserData: (state, action: PayloadAction<number>) => {
      if (state.userData !== undefined) {
        const indexOfCharacterToDelete = state.userData.characters.findIndex(ch => ch.Id === action.payload);
        if (indexOfCharacterToDelete !== -1) {
          state.userData.characters.splice(indexOfCharacterToDelete, 1);
          updateUserData(state.userData);
        }
      }
    },
    addNewGroupToUserData: (state, action: PayloadAction<GroupType>) => {
      if (state.userData !== undefined)
        state.userData.groups.push(action.payload);
    },
  }
});

export const {
  loginUser,
  logoutUser,
  addOrEditCharacterToUserData,
  editProfileInUserData,
  addCharactersToUserData,
  deleteCharacterFromUserData,
  addNewGroupToUserData
} = authSlice.actions;

export default authSlice.reducer;
