import {CharacterTreeType} from "../adapters/types";

export const getMonthName = (monthIndex: string | number): string => {
  if (isNaN(+monthIndex)) return '-';
  const _monthIndex: number = +monthIndex;  // string to number
  if (_monthIndex < 1 || _monthIndex > 12) return '-';
  return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
         [_monthIndex - 1];
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

/**
 * Python-like range. Tested on integers. Supports reversed output i.e. step is negative.
 * Needs improvement on floats. If it's needed use parseFloat(), toFixed(), etc. functions at each yield.
 * @param start
 * @param end Last number from generator will be end +- step (if end +- step >= or <= start) or start or end.
 * @param step
 */
export function* range (start: number, end: number, step: number = 1) {
  if (step === 0) return;
  for (let i = start; step >= 0 ? i < end : i > end; i += step)
    yield i;
}

/**
 * @param iterator Tested on generators `range()` and arrays.
 * @param mappingFunction Takes required argument: value, and optional argument: loop counter
 * @param loopCounterStart
 */
export const map = (iterator: any, mappingFunction: Function, loopCounterStart: number = 0) => {
  let mappedValues = [];
  for (const value of iterator) {
    mappedValues.push(mappingFunction(value, loopCounterStart));
    loopCounterStart++;
  }
  return mappedValues;
}

export const orderInPlaceCharacters = (characters: CharacterTreeType[]) => {
  characters.sort((firstEl, secondEl) => {
    const firstName1 = firstEl.FirstName.toLowerCase(), lastName1 = firstEl.LastName.toLowerCase();
    const firstName2 = secondEl.FirstName.toLowerCase(), lastName2 = secondEl.LastName.toLowerCase();
    if (lastName1 === lastName2) {
      return firstName1 < firstName2 ? -1 : firstName1 > firstName2 ? 1 : 0;
    }
    return lastName1 < lastName2 ? -1 : 1;
  });
}

export const capitalize = (str: string) => {
  return str.replace(str[0], str[0].toUpperCase());
}

export const getTimestampInMilliSeconds = (): number => Date.now();

export const getRandomString = (length: number): string => {
  let result: string = "";
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}