import React, {useState} from "react";
import {useAppDispatch} from "../../app/hooks";
import {apiLoginUser} from "../../adapters/authorization";
import {Link, useHistory, useLocation} from "react-router-dom";
import {loginUser} from "pages/authorization/context/authSlice";
import {LocationLogoutRedirectStateType} from "./types";
import {useMutation} from "react-query";
import {InputLine} from "../../components/forms/InputLine";
import {AxiosError} from "axios";
import {ErrorContactUs} from "./components/ErrorContactUs";
import styles from "./styles/login.module.scss";
import appStoreImage from "static/app_store.png";

export const Login = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | React.ReactNode>(location.state === undefined ? ""
        : (location.state as LocationLogoutRedirectStateType).errorMessage || "");
  const [loginBtnDisabled, setLoginBtnDisabled] = useState<boolean>(false);

  const mutation = useMutation(apiLoginUser, {
    onSuccess: (response) => {
      dispatch(loginUser(response.data.Content));
      history.push("/stories");
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 401) setErrorMessage(error.response.data.Message);
      else                                setErrorMessage(<ErrorContactUs />);
    },
    onSettled: () => {
      setLoginBtnDisabled(false);
    }
  });

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoginBtnDisabled(true);
    mutation.mutate({email: email, password: password});
    return;
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
    setErrorMessage("");
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
    setErrorMessage("");
  }

  return (
    <div className="mt-5 mb-5 ms-5 me-5">
      <header>
        <h1><b>Login</b> <small>to</small> <br/> Storychest<sup className="text-black">*</sup></h1>
      </header>
      <div className="mt-3 storychest-bg-color">
        <span className="text-white">{errorMessage}</span>
      </div>
      <div className="mt-3">
        <form method="post" onSubmit={handleSubmit}>
          <InputLine id="email" type="email" onChange={handleEmailChange} />
          <InputLine id="password" type="password" onChange={handlePasswordChange} />
          <button type="submit" className="btn btn-danger mt-3" disabled={loginBtnDisabled}>
            Login
          </button>
        </form>
      </div>
      <div className="mt-4 text-start">
        <Link to="/forgot-password" id={styles.forgotPasswordLink}>Forgot Password?</Link>
      </div>
      <div className="text-justify text-black font-breelight mt-4">
        *This web based version of Storychest is only available as a companion application 
        for users who have downloaded and registered in the Apple iOS app.
      </div>
      <div className="text-start mt-2" style={{height: "55px", width: "165px"}}>
        <a href="https://apps.apple.com/us/app/storychest/id1207555994?ls=1" target="_blank" rel="noreferrer">
          <div
            id={`${styles.appStoreBgImage}`}
            style={{backgroundImage: `url(${appStoreImage})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", height: "100%"}}>
          </div>
        </a>
      </div>
    </div>
  );
};