import axios from "axios";
import config from "config";
import {getAccessToken, getAndSetIfNullDeviceId, getRefreshToken, getUserId, setToken} from "./localStorage";
import createSnackbar, {SnackTypes} from "../components/snackbar/Snackbar";

/** Use this axios provider to make API calls. */
export const axiosProvider = axios.create({
	baseURL: config.API_URL,
	headers: {
		"Content-Type": "application/json"  // default content type that can be directly changed when creating new request
	},
	validateStatus: (status) => {  // default is described in https://github.com/axios/axios#request-config (200 <= status < 300)
		return status === 200;
	}
});

/** Reference to current promise that is refreshing token.
 *  Use it to prevent multiple calls on refreshing token i.e. wait until promise is full-filled.
 */
let refreshingTokenPromise: Promise<any> | null = null;

let logoutRedirected: boolean = false;

export const setupInterceptors = (history: any) => {

	// token and user id
	axiosProvider.interceptors.request.use((config) => {
		if (config.headers !== undefined)
			config.headers["Authorization"] = `Bearer ${getAccessToken()}`;
		if (config.data === undefined)
			config.data = {}
		if (typeof config.data === "object")
			config.data.userId = getUserId();
		return config;
	});

	// refreshing token
	axiosProvider.interceptors.response.use((response) => {
			logoutRedirected = false;
			return response;
		}, async (error) =>
		{
			console.log(error);
			const originalRequest = error.config;
			if (error.response.status === 401) {
				if (refreshingTokenPromise === null) {  // refresh token for current request
					refreshingTokenPromise = axiosProvider.post("/refresh-token", {
						refreshToken: getRefreshToken(),
						deviceId: getAndSetIfNullDeviceId()
					});
					console.log("Refreshing token...");
				}
				try {
					const response = await refreshingTokenPromise;
					// response status is here always 200 because of our configuration (validateStatus)
					refreshingTokenPromise = null;
					setToken(response.data.Content.accessToken, response.data.Content.refreshToken);
					console.log("Token has been refreshed. Repeating request.");
					return axiosProvider(originalRequest);
				}
				catch (error) {
					if (!logoutRedirected) {
						logoutRedirected = true;
						console.error("Unable to refresh token.");
						createSnackbar("Your session expired. Please login again.", SnackTypes.warning);
						history.push("/logout");
					}
				}
			}
			return Promise.reject(error);
		}
	);
}