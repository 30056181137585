import {DateItem} from "components/DateItem";
import {Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";
import {ApiStoriesResponseContentType} from "adapters/types";
import {SetShowModal} from "app/types";
import styles from "styles/stories/story_item.module.scss";

export interface StoryItemProps {
	story: ApiStoriesResponseContentType,
	setShowStoryPreviewModal: SetShowModal,
	setSelectedStoryId: React.Dispatch<React.SetStateAction<number>>,
	setShowYesNoModal: SetShowModal,
	setYesNoModalText: React.Dispatch<React.SetStateAction<string>>,
	setToDeleteStoryId: React.Dispatch<React.SetStateAction<number>>
}

export const StoryItem = (props: StoryItemProps) => {

	const deleteStoryLinkClassName = "deleteStoryLink";

	const handleDeleteStoryClick = (storyId: number, storyHeadline: string) => {
		props.setShowYesNoModal(true);
		props.setYesNoModalText(`Are you sure you want to permanently delete a story '${storyHeadline}'?`)
		props.setToDeleteStoryId(storyId);
	}

	const handleStoryItemClick = (storyId: number) => {
		props.setShowStoryPreviewModal(true);
		props.setSelectedStoryId(storyId);
	}

	return (
		<figure className={`${styles.storyItemFigure} figure cursor-pointer`}
		        onClick={() => handleStoryItemClick(props.story.Id)}
		>
			<div className={styles.storyItemBgImageContainer}>
				<div className={styles.storyItemBgImage} style={{backgroundImage: `url("${props.story.CoverImage}")`}}/>
			</div>
			<figcaption className="figure-caption ps-3 pe-3 pt-3 text-start">
				<div className={`w-100 ${styles.storyItemContentContainer}`}>
					<DateItem dateTxt={props.story.CustomDate.DateTxt} className="float-start"/>
					<div className="float-end" onClick={(e) => e.stopPropagation()}>
						<Dropdown>
							{/* TODO: dropdown toggle as button */}
							<Dropdown.Toggle className={`${styles.wrapperIconDots}`} id="dropdownMenuButton" as="div">
								<span className={`${styles.iconDots} icon-dots cursor-pointer`}/>
							</Dropdown.Toggle>
							<Dropdown.Menu className={`${styles.storyMoreDropdownMenu}`}>
								<Dropdown.Item as={Link} to={`/story/${props.story.Id}/edit`}>
									<div className="wrapper-icon justify-content-start">
										<span className="icon-pen pe-2 storychest-color" />
										<span>Edit story</span>
									</div>
								</Dropdown.Item>
								<Dropdown.Item as={Link} to="#">
									<div className="wrapper-icon justify-content-start">
										<span className="icon-share pe-2 storychest-color" />
										<span>Share story</span>
									</div>
								</Dropdown.Item>
								<Dropdown.Item as={"div"} className={`${deleteStoryLinkClassName} cursor-pointer`}
															 onClick={() => handleDeleteStoryClick(props.story.Id, props.story.Headline)}
									>
									<div className={`${deleteStoryLinkClassName} wrapper-icon justify-content-start`}>
										<span className={`${deleteStoryLinkClassName} icon-bin pe-2 storychest-color`}/>
										<span className={`${deleteStoryLinkClassName} storychest-color`}>Delete story</span>
									</div>
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
					<div className="pt-2 clear-both">
						<h2 className={styles.storyItemHeadline}>{props.story.Headline}</h2>
					</div>
					<div className={styles.storyItemDescription}>{props.story.Description}</div>
				</div>
				<div>
					<hr/>
				</div>
				<div className="wrapper-icon">
					<span className="icon-heart1 storychest-color"/>
					<span className={`ps-2 ${styles.storyItemLikesText}`}>{props.story.Likes}</span>
				</div>
			</figcaption>
		</figure>
	)
}